
import store from "@/plugins/vuex"
import { hasAccessToRoute, hasClientAccessToRoute } from "@/utils/access"

/**
 * This method checks if the logged in user has access to the route.
 * @param {*} to route to which access has to be checked.
 */

const setLoggedInUserRoleTypes = async () => {
  let rolesMap = store.getters["roles/rolesMap"]
  if (!Object.keys(rolesMap).length) {
    await store.dispatch("roles/loadRoles")
    rolesMap = store.getters["roles/rolesMap"]
  }

  const loggedInUser          = store.getters["auth/loggedInUser"]
  const loggedInUserRoleTypes = new Set()
  for (const role of loggedInUser.roles) {
    if (rolesMap[role]?.global) {
      loggedInUserRoleTypes.add("global")
    } else if (rolesMap[role]?.issue) {
      loggedInUserRoleTypes.add("issue")
    }
  }
  store.commit("auth/setLoggedInUserRoleTypes", Array.from(loggedInUserRoleTypes))
}

const setLoggedInUserPolicies = async () => {
  if (!Object.keys(store.getters["accessControl/loggedInUserPolicies"]).length) {
    await store.dispatch("accessControl/loadLoggedInUserAccess")
  }
}

/**
 * This method will be called before navigating to any protected page.
 * @param {*} to route to be navigated to.
 * @param {*} from route from where navigation was triggered.
 */
export const beforeProtectedRoute = async (to, from) => {
  const loggedInUser = store.getters["auth/loggedInUser"]
  if (!store.getters["auth/isLoggedIn"]) {
    return "logout"
  } else if (!from.name || ["login", "reset-password"].includes(from.name)) {
    if (!store.getters["auth/isLoggedIn"]) {
      return "logout"
    } else {
      store.dispatch("configurations/loadConfigurations")
      if (loggedInUser.roles.length) {
        const loadUserPromise = store.dispatch("users/loadUser", loggedInUser.id)
        store.dispatch("issueTypes/loadIssueTypes")
        store.dispatch("issueStatuses/loadIssueStatuses")
        store.dispatch("issueResolutions/loadIssueResolutions")
        store.dispatch("languages/loadLanguages")
        store.dispatch("translationPreferences/loadTranslationPreferences")
        store.dispatch("workflows/loadWorkflows")
        store.dispatch("workflowAssociations/loadWorkflowAssociations")

        await Promise.all([
          loadUserPromise,
          setLoggedInUserRoleTypes(),
          setLoggedInUserPolicies()
        ])
        if (to.name !== "dashboard" && !hasAccessToRoute(to.meta.roleTypes)) {
          return "dashboard"
        }
      } else {
        if (!["dashboard", "user-settings"].includes(to.name)) {
          return "dashboard"
        }
      }
    }
  }
  if (!hasClientAccessToRoute(to.name)) {
    return "dashboard"
  }
}