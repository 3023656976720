import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"
import store from "@/plugins/vuex"
import { ISSUE_TYPES } from "@/constants"

const path = "/v1/transitions"

export default {
  /**
   * This action will make a transition for an issue
   * @param {*} context is the store
   * @param {*} payload contains transition id and data
   */
  async makeTransition(context, payload) {
    let hasAccessToDomain = false
    context.commit("setTransitionDone", false)
    context.commit("setTransitionInProgress", true)
    context.commit("setLastCreatedIssue", null)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const accesses = store.getters["accesses/accesses"]

    const postTransitionResponse = await httpClient.post(`/${payload.id}`, payload.data)

    if (postTransitionResponse.status === HTTP_STATUS_CODE.OK) {
      const loggedInUser         = store.getters["auth/loggedInUser"]
      const groupsOfUsers        = store.getters["users/groupsOfUsers"]
      const loggedInUserGroupIds = groupsOfUsers[loggedInUser.id]

      const accessToUser = accesses.filter(access =>
        loggedInUser.id === access.userId || loggedInUserGroupIds?.includes(access.groupId))

      hasAccessToDomain = accessToUser.find(access =>
        access.criteria?.domainId === postTransitionResponse.data?.domainId)

      const issues = store.getters["issues/issues"]
      const issue  = issues.find(issue => issue.id === postTransitionResponse.data.id)
      if (!issue) {
        context.commit("setLastCreatedIssue", postTransitionResponse.data.id)
      }
      store.commit("issues/updateIssues", [postTransitionResponse.data])
      if (payload.data.issueFieldValues) {
        store.commit("issueFieldValues/updateIssueFieldValues", postTransitionResponse.data.issueFieldValues)
      }

      context.commit("setTransitionDone", true)
    }
    if (!payload.data?.id && !hasAccessToDomain && payload.data.typeId === ISSUE_TYPES[0].id) {
      await store.dispatch("issues/loadIssuesFromLastSearch")
    }
    context.commit("setTransitionInProgress", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setTransitionDone", false)
    context.commit("setTransitionInProgress", false)
  }
}