import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/reply-templates"

export default {
  /**
   * This action will load reply templates.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load reply templates.
   */
  async loadReplyTemplates(context, payload) {
    context.commit("setLoadingReplyTemplates", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getReplyTemplatesResult = await httpClient.get(undefined, {
      params: payload
    })

    if (getReplyTemplatesResult.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateReplyTemplates", getReplyTemplatesResult.data)
      } else {
        context.commit("setReplyTemplates", getReplyTemplatesResult.data)
      }
    }

    context.commit("setLoadingReplyTemplates", false)
  },

  /**
   * This action will add a reply template.
   * @param {*} context is the store.
   * @param {*} payload contains name of the reply template.
   */
  async addReplyTemplate(context, payload) {
    context.commit("setAddingReplyTemplate", true)
    context.commit("setReplyTemplateAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const addReplyTemplateResponse = await httpClient.post(undefined, payload)

    if (addReplyTemplateResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateReplyTemplates", [addReplyTemplateResponse.data])
      context.commit("setReplyTemplateAdded", true)
    } else if (addReplyTemplateResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setReplyTemplateAddError", addReplyTemplateResponse.data)
    }

    context.commit("setAddingReplyTemplate", false)
  },

  /**
   * This action will load a particular reply template.
   * @param {*} context it is the store.
   * @param {*} payload it is id of the reply template to be loaded
   */
  async loadReplyTemplate(context, payload) {
    context.commit("setLoadingReplyTemplates", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getReplyTemplateResponse = await httpClient.get(`/${payload}`)

    if (getReplyTemplateResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateReplyTemplates", [getReplyTemplateResponse.data])
    }
    context.commit("setLoadingReplyTemplates", false)
  },

  /**
   * This action updates reply template
   * @param {*} context is the store.
   * @param {*} replyTemplate is the reply template to be updated.
   */
  async updateReplyTemplate(context, replyTemplate) {
    const id = replyTemplate.id
    delete replyTemplate.id

    const propertiesToBeUpdated = Object.keys(replyTemplate)
    for (const property of propertiesToBeUpdated) {
      if (replyTemplate[property] === undefined) {
        replyTemplate[property] = null
      }
    }
    context.commit("setUpdatingReplyTemplate", propertiesToBeUpdated)
    context.commit("resetReplyTemplateUpdated", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateReplyTemplateResponse = await httpClient.put(`/${id}`, replyTemplate)

    if (updateReplyTemplateResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateReplyTemplates", [{
        id,
        ...replyTemplate
      }])
      context.commit("setReplyTemplateUpdated", propertiesToBeUpdated)
    } else if (updateReplyTemplateResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setReplyTemplateUpdateError", updateReplyTemplateResponse.data)
    }
    context.commit("resetUpdatingReplyTemplate", propertiesToBeUpdated)
  },

  /**
   * This action removes reply template.
   * @param {*} context is the store.
   * @param {*} payload contains the id of the reply template
   */
  async removeReplyTemplate(context, payload) {
    context.commit("setRemovingReplyTemplate", true)
    context.commit("setReplyTemplateRemoved", false)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteReplyTemplateResponse = await httpClient.delete(`/${payload}`)

    if (deleteReplyTemplateResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("replyTemplateRemove", payload)
      context.commit("setReplyTemplateRemoved", true)
    }

    context.commit("setRemovingReplyTemplate", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingReplyTemplates", false)
    context.commit("setReplyTemplates", new Array())
    context.commit("setAddingReplyTemplate", false)
    context.commit("setReplyTemplateAdded", false)
    context.commit("setReplyTemplateAddError", undefined)
    context.commit("setUpdatingReplyTemplate", new Array())
    context.commit("resetReplyTemplateUpdated")
    context.commit("resetUpdatingReplyTemplate")
    context.commit("setReplyTemplateUpdated", new Array())
    context.commit("setReplyTemplateUpdateError", false)
    context.commit("setRemovingReplyTemplate", false)
    context.commit("setReplyTemplateRemoved", false)
  }
}