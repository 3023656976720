/**
 * @file It contains all the vuex roles module state variables.
 */
export default {
  loadingRoles   : false,
  roles          : new Array(),
  addingRole     : false,
  roleAdded      : false,
  roleAddError   : null,
  isUpdatingRole : false,
  roleUpdateError: null,
  isRoleUpdated  : {
    name       : false,
    description: false,
    permissions: false
  }
}