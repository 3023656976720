export default {
  loadingFolders: false,
  folders       : new Array(),
  addingFolder  : false,
  folderAdded   : false,
  removingFolder: false,
  folderRemoved : false,
  updatingFolder: {
    parentId: false,
    name    : false
  },
  folderUpdated: {
    parentId: false,
    name    : false
  }
}