import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/issue-types"

export default {
  /**
   * This action will load issue types.
   * @param {*} context is the store.
   */
  async loadIssueTypes(context) {
    context.commit("setLoadingIssueTypes", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getIssueTypesResponse = await httpClient.get()

    if (getIssueTypesResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setIssueTypes", getIssueTypesResponse.data)
    }

    context.commit("setLoadingIssueTypes", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setIssueTypes", new Array())
    context.commit("setLoadingIssueTypes", false)
  }
}