/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/organisation-codes"

export default {
  /**
   * This action will load access codes.
   * @param {*} context is the store
   */
  async loadOrganisationCodes(context) {
    context.commit("setLoadingOrganisationCodes", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getOrganisationCodesResponse = await httpClient.get()

    if (getOrganisationCodesResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setOrganisationCodes", getOrganisationCodesResponse.data)
    } else {
      // something went wrong while getting access codes
    }

    context.commit("setLoadingOrganisationCodes", false)
  },

  /**
   * This action will load available access codes.
   * @param {*} context is the store
   */
  async loadAvailableOrganisationCodes(context) {
    context.commit("setLoadingAvailableOrganisationCodes", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getAvailableOrganisationCodesResponse = await httpClient.get(
      undefined, {
        params: {
          available: true
        }
      }
    )

    if (getAvailableOrganisationCodesResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setAvailableOrganisationCodes", getAvailableOrganisationCodesResponse.data)
    } else {
      // something went wrong while getting available access codes
    }

    context.commit("setLoadingAvailableOrganisationCodes", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setOrganisationCodes", new Array())
    context.commit("setLoadingOrganisationCodes", false)
    context.commit("setAvailableOrganisationCodes", new Array())
    context.commit("setLoadingAvailableOrganisationCodes", false)
  }
}