export default {
  screens              : state => state.screens,
  isLoadingScreens     : state => state.loadingScreens,
  isAddingScreen       : state => state.addingScreen,
  isScreenAdded        : state => state.screenAdded,
  screenAddError       : state => state.screenAddError,
  isUpdatingScreen     : state => state.updatingScreen,
  isScreenUpdated      : state => state.screenUpdated,
  screenUpdateError    : state => state.screenUpdateError,
  screenItems          : state => state.screenItems,
  isLoadingScreenItems : state => state.loadingScreenItems,
  isScreenItemsAdded   : state => state.screenItemsAdded,
  isAddingScreenItems  : state => state.addingScreenItems,
  screenNameUpdateError: state => state.screenUpdateError.name,
  isUpdatingScreenName : state => state.updatingScreen.name,
  isScreenNameUpdated  : state => state.screenUpdated.name
}