/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE, AI_PREFILL_FIELDS_STATUS, BULK_IMPORT_STATUS } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/issues"

export default {
  /**
   * This action will load issues.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load issues.
   */
  async loadIssues(context, payload) {
    context.commit("setLoadingIssues", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getIssuesResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getIssuesResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateIssues", getIssuesResponse.data)
      } else {
        context.commit("setIssues", getIssuesResponse.data)
      }
    } else {
      // something went wrong while getting issues
    }

    context.commit("setLoadingIssues", false)
  },

  /**
   * This action will load issues from last search.
   * @param {*} context is the store.
   */
  async loadIssuesFromLastSearch(context) {
    context.commit("setLoadingIssues", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getIssuesResponse = await httpClient.get("?lastSearch=true")

    if (getIssuesResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setLastSearchIssues", getIssuesResponse.data)
    }

    context.commit("setLoadingIssues", false)
  },

  /**
   * This action will load a particular issue.
   * @param {*} context it is the store.
   * @param {*} payload it is id of the issue to be loaded
   */
  async loadIssue(context, payload) {
    context.commit("setLoadingIssues", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getIssueResponse = await httpClient.get(`/${payload}`)

    if (getIssueResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateIssues", [getIssueResponse.data])
    } else {
      // something went wrong while getting an issue
    }

    context.commit("setLoadingIssues", false)
  },

  /**
   * This action updates summary of an issue.
   * @param {*} context is the store.
   * @param {*} payload id and summary of an issue.
   */
  async updateIssue(context, issue) {
    const id = issue.id
    delete issue.id
    const propertiesToBeUpdated = Object.keys(issue)
    for (const property of propertiesToBeUpdated) {
      if (issue[property] === undefined) {
        issue[property] = null
      }
    }
    context.commit("setUpdatingIssue", propertiesToBeUpdated)
    context.commit("resetIssueUpdated", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateIssueResponse = await httpClient.put(`/${id}`, issue)

    if (updateIssueResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateIssues", [{
        id,
        ...issue
      }])
      context.commit("setIssueUpdated", propertiesToBeUpdated)
    } else {
      // something went wrong while updating an issue
    }

    context.commit("resetUpdatingIssue", propertiesToBeUpdated)
  },

  /**
   * This action will add a issue.
   * @param {*} context is the store.
   */
  async addIssue(context, payload) {

    context.commit("setAddingIssue", true)
    context.commit("setIssueAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postIssueResponse = await httpClient.post(undefined, payload)

    if (postIssueResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateIssues", [postIssueResponse.data])
      context.commit("setIssueAdded", true)
    } else {
      // something went wrong while adding an issue
    }

    context.commit("setAddingIssue", false)
  },

  /**
 * This action will load logs of a particular issue.
 * @param {*} context it is the store.
 * @param {*} payload it is id of the issue to be loaded
 */
  async loadLogs(context, payload) {
    context.commit("setLoadingLogs", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getLogsResponse = await httpClient.get(`/${payload}/logs`)

    if (getLogsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setLogsOfIssue", {
        id  : payload,
        logs: getLogsResponse.data
      })
    } else {
      // something went wrong while getting an issue
    }

    context.commit("setLoadingLogs", false)
  },

  /**
 * This action will load comments of particular issue.
 * @param {*} context it is the store.
 * @param {*} payload it is id of the issue to be loaded
 */
  async loadComments(context, payload) {
    context.commit("setLoadingComments", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getCommentsResponse = await httpClient.get(`/${payload}/comments`)

    if (getCommentsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setCommentsOfIssue", {
        id      : payload,
        comments: getCommentsResponse.data
      })
    } else {
      // something went wrong while getting an issue
    }

    context.commit("setLoadingComments", false)
  },

  /**
   * This action will add a comment.
   * @param {*} context is the store.
   */
  async addComment(context, { id, comment }) {
    context.commit("setCommentAdded", false)
    context.commit("setAddingComment", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postCommentsResponse = await httpClient.post(`/${id}/comments`, comment)

    if (postCommentsResponse?.status === HTTP_STATUS_CODE.OK) {
      context.commit("appendCommentToIssue", {
        id,
        comment: postCommentsResponse.data
      })
      context.commit("setCommentAdded", true)
    } else {
      // something went wrong while adding a comment
    }

    context.commit("setAddingComment", false)
  },

  /**
   * This action will add a comment.
   * @param {*} context is the store.
   */
  async removeComment(context, { id, commentId }) {
    context.commit("setCommentRemoved", false)
    context.commit("setRemovingComment", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteCommentResponse = await httpClient.delete(`/${id}/comments/${commentId}`)

    if (deleteCommentResponse?.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("removeCommentFromIssue", {
        id,
        commentId
      })
      context.commit("setCommentRemoved", true)
    } else {
      // something went wrong while adding a comment
    }

    context.commit("setRemovingComment", false)
  },

  /**
   * This action loads the aiPrefillField details.
   * @param {*} context is the store.
   * @param {*} payload contains id of the aiPrefillField.
   */
  async loadAiPrefillField(context, payload ) {
    if (payload?.status === AI_PREFILL_FIELDS_STATUS.INITIATED) {
      delete payload.status
      context.commit("setLoadingAiPrefillField", false)
    } else {
      context.commit("setLoadingAiPrefillField", true)
    }
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const id        = payload.id
    const prefillId = payload.prefillId

    const getAiPrefillFieldResponse = await httpClient.get(`/${id}/ai-prefill/${prefillId}`)
    if (getAiPrefillFieldResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setAiPrefillField", getAiPrefillFieldResponse.data)
    }
    context.commit("setLoadingAiPrefillField", false)
  },

  /**
   * This action will add new aiPrefillField for an issue.
   * @param {*} context is the store.
   * @param {*} payload is the name to add to the aiPrefillField
   */
  async addAiPrefillField(context, payload) {
    context.commit("setAddingAiPrefillField", true)
    context.commit("setAiPrefillFieldAdded", false)
    const id = payload.id

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postAiPrefillFieldResponse = await httpClient.post(`/${id}/ai-prefill`)
    if (postAiPrefillFieldResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setAiPrefillField", postAiPrefillFieldResponse.data)
      context.commit("setAiPrefillFieldAdded", true)
    }
    context.commit("setAddingAiPrefillField", false)
  },

  /**
   * This action upload csv file to bulk import cases.
   * @param {*} context is the store.
   * @param {*} payload contains file to be uploaded.
   */
  async uploadCsvFilesToBulkImportCases(context, payload) {
    context.commit("setUploadingCsvForBulkImportCases", true)
    context.commit("setCsvFileUploadedForBulkImportCases", false)

    const { name, file, domainId, speakupFieldsMapping, firstRowIsHeader } = payload

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const uploadUrlResponse = await httpClient.post("/bulk-imports", { name })
    if (uploadUrlResponse.status === HTTP_STATUS_CODE.OK) {
      const form = new FormData()
      Object.keys(uploadUrlResponse.data.fields).forEach(key =>
        form.append(key, uploadUrlResponse.data.fields[key]))
      form.append("file", file)

      const response = await fetch(uploadUrlResponse.data.url, {
        method: "POST",
        body  : form
      })
      const id       = uploadUrlResponse.data.id
      if (response.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
        await httpClient.put(`/bulk-imports/${id}`, {
          status: BULK_IMPORT_STATUS.INITIATED,
          domainId,
          firstRowIsHeader,
          speakupFieldsMapping
        })
      }
    }
    context.commit("setCsvFileUploadedForBulkImportCases", true)
    context.commit("setUploadingCsvForBulkImportCases", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setIssues", new Array())
    context.commit("setLoadingIssues", undefined)
    context.commit("setLoadingLogs", undefined)
    context.commit("setLoadingComments", undefined)
    context.commit("setAddingComment", undefined)
    context.commit("setAddingIssue", undefined)
    context.commit("setIssueAdded", undefined)
    context.commit("setCommentAdded", undefined)
    context.commit("setAiPrefillField", new Object())
    context.commit("setLoadingAiPrefillField", undefined)
    context.commit("setAddingAiPrefillField", undefined)
    context.commit("setAiPrefillFieldAdded", undefined)
    context.commit("resetUpdatingIssue")
    context.commit("resetCommentsOfIssues")
    context.commit("resetLogsOfIssues")
    context.commit("resetIssueUpdated")
    context.commit("resetAccessInformation")
    context.commit("setCsvFileUploadedForBulkImportCases", undefined)
    context.commit("setUploadingCsvForBulkImportCases", undefined)
  }
}