import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/automations"

export default {
  /**
   * This action will load automations.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load automations.
   */
  async loadAutomations(context, payload) {
    context.commit("setLoadingAutomations", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getAutomationsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getAutomationsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateAutomations", [getAutomationsResponse.data])
      } else {
        context.commit("setAutomations", getAutomationsResponse.data)
      }
    }

    context.commit("setLoadingAutomations", false)
  },

  /**
   * This action will add a automation.
   * @param {*} context is the store.
   * @param {*} payload is the data of automation.
   */
  async addAutomation(context, payload) {
    context.commit("setAddingAutomation", true)
    context.commit("setAutomationAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postAutomationResponse = await httpClient.post(undefined, payload)

    if (postAutomationResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateAutomations", [postAutomationResponse.data])
      context.commit("setAutomationAdded", true)
    } else if (postAutomationResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setAutomationAddError", postAutomationResponse.data)
    }

    context.commit("setAddingAutomation", false)
  },

  /**
   * This action updates automation.
   * @param {*} context is the store.
   * @param {*} payload is the automation id and details to be updated.
  */
  async updateAutomation(context, automation) {
    const id = automation.id
    delete automation.id
    const propertiesToBeUpdated = Object.keys(automation)
    for (const property of propertiesToBeUpdated) {
      if (automation[property] === undefined) {
        automation[property] = null
      }
    }
    context.commit("setUpdatingAutomation", propertiesToBeUpdated)
    context.commit("resetAutomationUpdated", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const putAutomationResponse = await httpClient.put(`/${id}`, automation)

    if (putAutomationResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateAutomations", [{
        id,
        ...automation
      }])
      context.commit("setAutomationUpdated", propertiesToBeUpdated)

    } else if (putAutomationResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setAutomationUpdateError", putAutomationResponse.data)
    }

    context.commit("resetUpdatingAutomation", propertiesToBeUpdated)
  },

  /**
   * This action removes automation.
   * @param {*} context is the store.
   * @param {*} payload is the automation id.
   */
  async removeAutomation(context, payload) {
    context.commit("setAutomationRemoved", false)
    context.commit("setRemovingAutomation", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteAutomationResponse = await httpClient.delete(`/${payload}`)

    if (deleteAutomationResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setAutomationRemoved", true)
      context.commit("removeAutomation", payload)
    }

    context.commit("setRemovingAutomation", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setAutomations", new Array())
    context.commit("setLoadingAutomations", false)
    context.commit("setAddingAutomation", false)
    context.commit("setAutomationAdded", false)
    context.commit("setAutomationAddError", undefined)
    context.commit("resetUpdatingAutomation")
    context.commit("resetAutomationUpdated")
    context.commit("setAutomationUpdateError", undefined)
  }
}
