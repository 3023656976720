import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { AUTOMATION_ADD } from "@/constants/bread-crumbs/automation-add"
import { BOOLEAN } from "../constants"

export const beforeAutomation = async to => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.AUTOMATION_ADD))
  store.commit("shared/setBreadcrumbs", AUTOMATION_ADD)

  store.dispatch("domains/loadDomains")
  const loadChannelsPromise            = store.dispatch("channels/loadChannels")
  const loadOptionListItemsPromise     = store.dispatch("optionListItems/loadOptionListItems")
  const loadFormTemplates              = store.dispatch("formTemplates/loadFormTemplates", {
    reportForm: BOOLEAN.TRUE
  })
  const loadFieldsPromise              = store.dispatch("fields/loadFieldsV2")
  const loadFormTemplateConfigurations = store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations")
  store.dispatch("users/loadUsers")
  store.dispatch("groups/loadGroups")

  if (to.name === "automation-edit") {
    const loadAutomationsPromise = store.dispatch("automations/loadAutomations")
    await loadAutomationsPromise

    const automations = store.getters["automations/automations"]
    const automation  = automations.find(automation => automation.id === +to.params.id)
    if (!automation) {
      return "automations"
    }
    await loadChannelsPromise
    await loadFormTemplateConfigurations
    await loadFieldsPromise
    await loadOptionListItemsPromise
    await loadFormTemplates
  }
}