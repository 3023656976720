import { defaultTableHeaderClasses, defaultTableFooterOptions, defaultTableItemsPerPage } from "@/constants"
import { compareDates }  from "@/utils"

export const EXCEPTIONS =  {
  headers: [{
    text : "98",
    align: "left",
    value: "createdAt",
    sort : compareDates,
    class: defaultTableHeaderClasses
  },
  {
    text : "99",
    align: "left",
    value: "type",
    class: defaultTableHeaderClasses
  },
  {
    text : "100",
    align: "left",
    value: "channelName",
    class: defaultTableHeaderClasses
  },
  {
    text : "101",
    align: "left",
    value: "issueId",
    class: defaultTableHeaderClasses
  },
  {
    text : "102",
    align: "left",
    value: "status",
    class: defaultTableHeaderClasses
  }],
  itemsPerPage: defaultTableItemsPerPage,
  footer      : defaultTableFooterOptions
}