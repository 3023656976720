export default {
  setPostFunctions: (state, postFunctions) => {
    state.postFunctions = postFunctions
  },
  updatePostFunctions: (state, postFunctions) => {
    for (const postFunction of postFunctions) {
      const index = state.postFunctions.findIndex(statePostFunction => statePostFunction?.id === postFunction.id)
      if (index >= 0) {
        state.postFunctions.splice(index, 1, { ...state.postFunctions[index], ...postFunction })
      } else {
        state.postFunctions.push(postFunction)
      }
    }
  }
}