export default {
  setLoadingPermissions: (state, loadingPermissions) => {
    state.loadingPermissions = loadingPermissions
  },
  setPermissions: (state, permissions) => {
    permissions.sort((firstPermission, secondPermission) => firstPermission.id - secondPermission.id)
    state.permissions = permissions
  },
  updatePermissions: (state, permissions) => {
    for (const permission of permissions) {
      const index = state.permissions.findIndex(statePermission =>
        statePermission?.id === permission.id)
      if (index >= 0) {
        state.permissions.splice(index, 1, { ...state.permissions[index], ...permission })
      } else {
        state.permissions.push(permission)
      }
    }
  }
}