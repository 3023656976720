import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/feedback"

export default {
  /**
   * this action will take the feedback for the feedback for the AI prefill field.
   * @param {*} context is the store.
   * @param {*} payload is the feedback to be added.
   */
  async addAiPrefillFieldFeedback(context, payload) {
    context.commit("setSubmittingAiPrefillFieldFeedback", true)
    context.commit("setAiPrefillFieldFeedbackSubmitted", false)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postAiPrefillFieldFeedbackResponse = await httpClient.post(undefined, payload)
    if (postAiPrefillFieldFeedbackResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setAiPrefillFieldFeedback", postAiPrefillFieldFeedbackResponse.data)
      context.commit("setAiPrefillFieldFeedbackSubmitted", true)
    }
    context.commit("setSubmittingAiPrefillFieldFeedback", false)
  },

  /**
   * This action will update the feedback for the AI prefill field.
   * @param {*} context is the store.
   * @param {*} payload contains the UUID and feedback data.
   */
  async updateAiPrefillFieldFeedback(context, payload) {
    const uuid = payload.uuid
    delete payload.uuid
    context.commit("setUpdatingAiPrefillFieldFeedback", true)
    context.commit("setAiPrefillFieldFeedbackUpdated", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateAiPrefillFieldFeedbackResponse = await httpClient.put(`/${uuid}`, payload)

    if (updateAiPrefillFieldFeedbackResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setAiPrefillFieldFeedback", payload)
      context.commit("setAiPrefillFieldFeedbackUpdated", true)
    }

    context.commit("setUpdatingAiPrefillFieldFeedback", false)
  },

  /* This action is used to reset store.
  * @param {*} context is the store.
  */
  reset(context) {
    context.commit("setAiPrefillFieldFeedback", new Object())
    context.commit("setSubmittingAiPrefillFieldFeedback", false)
    context.commit("setAiPrefillFieldFeedbackSubmitted", false)
    context.commit("setUpdatingAiPrefillFieldFeedback", false)
    context.commit("setAiPrefillFieldFeedbackUpdated", false)
  }
}