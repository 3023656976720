export default {
  setLoadingReplyTemplates: (state, loadingReplyTemplates) => {
    state.loadingReplyTemplates = loadingReplyTemplates
  },
  setReplyTemplates: (state, replyTemplates) => {
    state.replyTemplates = replyTemplates
  },
  setAddingReplyTemplate: (state, addingReplyTemplate) => {
    state.addingReplyTemplate = addingReplyTemplate
  },
  setReplyTemplateAdded: (state, replyTemplateAdded) => {
    state.replyTemplateAdded = replyTemplateAdded
  },
  setReplyTemplateAddError: (state, replyTemplateAddError) => {
    state.replyTemplateAddError = replyTemplateAddError
  },
  updateReplyTemplates: (state, replyTemplates) => {
    for (const replyTemplate of replyTemplates) {
      const index = state.replyTemplates.findIndex(stateReplyTemplates => stateReplyTemplates?.id === replyTemplate.id)
      if (index >= 0) {
        state.replyTemplates.splice(index, 1, { ...state.replyTemplates[index], ...replyTemplate })
      } else {
        state.replyTemplates.push(replyTemplate)
      }
    }
  },
  setUpdatingReplyTemplate: (state, properties) => {
    for (const property of properties) {
      state.updatingReplyTemplate[property] = true
    }
  },
  resetUpdatingReplyTemplate: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingReplyTemplate[property] = false
      }
    } else {
      Object.keys(state.updatingReplyTemplate).forEach(property => state.updatingReplyTemplate[property] = false)
    }
  },
  setReplyTemplateUpdated: (state, properties) => {
    for (const property of properties) {
      state.replyTemplateUpdated[property] = true
      setTimeout(() => {
        state.replyTemplateUpdated[property] = false
      }, 5000)
    }
  },
  resetReplyTemplateUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.replyTemplateUpdated[property] = false
      }
    } else {
      Object.keys(state.replyTemplateUpdated).forEach(property => state.replyTemplateUpdated[property] = false)
    }
  },
  setReplyTemplateUpdateError: (state, replyTemplateUpdateError) => {
    state.replyTemplateUpdateError = replyTemplateUpdateError
  },
  setRemovingReplyTemplate: (state, removingReplyTemplate) => {
    state.removingReplyTemplate = removingReplyTemplate
  },
  setReplyTemplateRemoved: (state, replyTemplateRemoved) => {
    state.replyTemplateRemoved = replyTemplateRemoved
  },
  replyTemplateRemove: (state, replyTemplateToBeRemovedId) => {
    const index = state.replyTemplates.
      findIndex(replyTemplate => replyTemplate.id === replyTemplateToBeRemovedId
      )
    state.replyTemplates.splice(index, 1)
  }
}