import { CONFIGURATIONS, SSO_STATE, STRING } from "@/constants"

export default {
  isLoadingConfigurations         : state => state.loadingConfigurations,
  configurations                  : state => state.configurations,
  isUpdatingValue                 : state => state.updatingConfiguration.value,
  isValueUpdated                  : state => state.configurationUpdated.value,
  isLoadingDefaultSsoConfiguration: state => state.loadingDefaultSsoConfiguration,
  isDefaultSsoConfigurationSaved  : state => state.defaultSsoConfigurationSaved,
  isSavingDefaultSsoConfiguration : state => state.savingDefaultSsoConfiguration,
  defaultSsoConfiguration         : state => state.defaultSsoConfiguration,
  defaultSsoConfigurationError    : state => state.defaultSsoConfigurationError,
  isEnablingDefaultSsoLogin       : state => state.enablingDefaultSsoLogin,
  isDefaultSsoLoginEnabled        : state => state.defaultSsoLoginEnabled,
  usersCount                      : state => {
    return +state.configurations.find(configuration =>
      configuration.key === CONFIGURATIONS.USERS_COUNT).value
  },
  isSsoAllowed: state => {
    const ssoConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.SSO
    )
    return ssoConfiguration?.value === STRING.TRUE
  },
  isDefaultSsoConfigured: state => state.configurations.find(
    configuration => configuration.key === CONFIGURATIONS.DEFAULT_SSO
  )?.value === SSO_STATE.CONFIGURED,
  isDefaultSsoEnabled: state => state.configurations.find(
    configuration => configuration.key === CONFIGURATIONS.DEFAULT_SSO
  )?.value === SSO_STATE.ENABLED,
  clientUserPoolId: state => state.configurations.find(
    configuration => configuration.key === CONFIGURATIONS.CLIENT_USER_POOL_ID
  )?.value,
  clientConfigurationId: state => state.configurations.find(
    configuration => configuration.key === CONFIGURATIONS.ID
  )?.value,
  isAiAnonymisationEnabled: state => {
    const aiAnonymisationConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.AI_ANONYMISATION
    )
    return aiAnonymisationConfiguration?.value === STRING.TRUE
  },
  channelsCount: state => {
    return +state.configurations.find(configuration =>
      configuration.key === CONFIGURATIONS.CHANNELS_COUNT).value
  },
  isIssueLinksEnabled: state => {
    const issueLinkConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.ISSUE_LINKS
    )
    return issueLinkConfiguration?.value === STRING.TRUE
  },
  isExtendedSearchEnabled: state => {
    const extendedSearchConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.EXTENDED_SEARCH
    )
    return extendedSearchConfiguration?.value === STRING.TRUE
  },
  isSharePointIntegrationEnabled: state => {
    const sharePointIntegrationConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.SHAREPOINT_INTEGRATION
    )
    return sharePointIntegrationConfiguration?.value === STRING.TRUE
  },
  isTasksEnabled: state => {
    const tasksConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.TASKS
    )
    return tasksConfiguration?.value === STRING.TRUE
  },
  isDataRetentionPeriodsEnabled: state => {
    const dataRetentionPeriodsConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.DATA_RETENTION_PERIODS
    )
    return dataRetentionPeriodsConfiguration?.value === STRING.TRUE
  },
  isIssueFieldsEnabled: state => {
    const issueFieldConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.ISSUE_FIELDS
    )
    return issueFieldConfiguration?.value === STRING.TRUE
  },
  isIssueFormsEnabled: state => {
    const issueFormsConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.ISSUE_FORMS
    )
    return issueFormsConfiguration?.value === STRING.TRUE
  },
  isReporterIntakeFormsEnabled: state => {
    const reporterIntakeFormConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.REPORTER_INTAKE_FORMS
    )
    return reporterIntakeFormConfiguration?.value === STRING.TRUE
  },
  isAutomationActionSendEmailEnabled: state => {
    const automationActionSendEmailConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.AUTOMATION_ACTION_SEND_EMAIL
    )
    return automationActionSendEmailConfiguration?.value === STRING.TRUE
  },
  isAutomationActionMoveIssueEnabled: state => {
    const automationActionMoveIssueConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.AUTOMATION_ACTION_MOVE_ISSUE
    )
    return automationActionMoveIssueConfiguration?.value === STRING.TRUE
  },
  isMentionCommentsEnabled: state => {
    const mentionComments = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.MENTION_COMMENTS
    )
    return mentionComments?.value === STRING.TRUE
  },
  isWorkflowsEnabled: state => {
    const workflowsConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.WORKFLOWS
    )
    return workflowsConfiguration?.value === STRING.TRUE
  },
  termsOfUse: state => {
    return state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.TERMS_OF_USE
    )
  },
  isTriageEnabled: state => {
    const triage = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.TRIAGE
    )
    return triage?.value === STRING.TRUE
  },
  isReplyTemplatesEnabled: state => {
    const replyTemplateConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.REPLY_TEMPLATES
    )
    return replyTemplateConfiguration?.value === STRING.TRUE
  },
  isAiPrefillFieldsEnabled: state => {
    const aiPrefillFieldsConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.AI_PREFILL_FIELDS
    )
    return aiPrefillFieldsConfiguration?.value === STRING.TRUE
  },
  isExportAllDataEnabled: state => {
    const allDataExportConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.EXPORT_ALL_DATA
    )
    return allDataExportConfiguration?.value === STRING.TRUE
  },
  isAiSuggestionsEnabled: state => {
    const aiSuggestionsConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.AI_SUGGESTIONS
    )
    return aiSuggestionsConfiguration?.value === STRING.TRUE
  },
  isCustomRolesEnabled: state => {
    const customRolesConfiguration = state.configurations.find(
      configuration => configuration.key === CONFIGURATIONS.CUSTOM_ROLES
    )
    return customRolesConfiguration?.value === STRING.TRUE
  }
}