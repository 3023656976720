import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/data-retention-rules"

export default {
  /**
   * This action will load dataRetentionRules.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load dataRetentionRules.
   */
  async loadDataRetentionRules(context, payload) {
    context.commit("setLoadingDataRetentionRules", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getDataRetentionRulesResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getDataRetentionRulesResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateDataRetentionRules", [getDataRetentionRulesResponse.data])
      } else {
        context.commit("setDataRetentionRules", getDataRetentionRulesResponse.data)
      }
    }

    context.commit("setLoadingDataRetentionRules", false)
  },

  /**
   * This action updates retention option for a rule.
   * @param {*} context is the store.
   * @param {*} dataRetentionRule id and value of a rule.
   */
  async updateDataRetentionRule(context, dataRetentionRule) {
    const { id, value } = dataRetentionRule

    context.commit("setUpdatingDataRetentionRule", true)
    context.commit("setDataRetentionRuleUpdated", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateDataRetentionRuleResponse = await httpClient.put(`/${id}`, { value })

    if (updateDataRetentionRuleResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateDataRetentionRules", [dataRetentionRule])
      context.commit("setDataRetentionRuleUpdated", true)
    }

    context.commit("setUpdatingDataRetentionRule", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setDataRetentionRules", new Array())
    context.commit("setLoadingDataRetentionRules", undefined)
    context.commit("setUpdatingDataRetentionRule", undefined)
    context.commit("setDataRetentionRuleUpdated", undefined)
  }
}