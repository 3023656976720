import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/form-templates"

export default {
  /**
   * This action will load form templates.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load forms.
   */
  async loadFormTemplates(context, payload) {
    context.commit("setLoadingFormTemplates", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getFormTemplatesResult = await httpClient.get(undefined, {
      params: payload
    })

    if (getFormTemplatesResult.status === HTTP_STATUS_CODE.OK) {
      context.commit("setFormTemplates", getFormTemplatesResult.data)
    }

    context.commit("setLoadingFormTemplates", false)
  },

  /**
   * This action will load a particular formTemplate.
   * @param {*} context it is the store.
   * @param {*} payload it is id of the formTemplate to be loaded
   */
  async loadFormTemplate(context, payload) {
    context.commit("setLoadingFormTemplates", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getFormTemplateResponse = await httpClient.get(`/${payload}`)

    if (getFormTemplateResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateFormTemplates", [getFormTemplateResponse.data])
    } else {
      // something went wrong while getting an FormTemplate
    }

    context.commit("setLoadingFormTemplates", false)
  },

  /**
   * This action will add new form template.
   * @param {*} context is the store.
   * @param {*} payload contains the details of a form template to be added.
   */
  async addFormTemplate(context, payload) {
    context.commit("setAddingFormTemplate", true)
    context.commit("setFormTemplateAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postFormTemplateResponse = await httpClient.post(undefined, payload)

    if (postFormTemplateResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateFormTemplates", [postFormTemplateResponse.data])
      context.commit("setFormTemplateAdded", true)
    } else if (postFormTemplateResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setFormTemplateAddError", postFormTemplateResponse.data)
    }
    context.commit("setAddingFormTemplate", false)
  },

  /**
   * This action updates summary of a reporter intake form.
   * @param {*} context is the store.
   * @param {*} payload contains the details of a form template to be updated.
   */
  async updateFormTemplate(context, formTemplate) {
    const id = formTemplate.id
    delete formTemplate.id

    const propertiesToBeUpdated = Object.keys(formTemplate)
    for (const property of propertiesToBeUpdated) {
      if (formTemplate[property] === undefined) {
        formTemplate[property] = null
      }
    }
    context.commit("setUpdatingFormTemplate", propertiesToBeUpdated)
    context.commit("resetFormTemplateUpdated", propertiesToBeUpdated)
    context.commit("resetFormTemplateUpdateError", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateFormTemplateResponse = await httpClient.put(`/${id}`, formTemplate)

    if (updateFormTemplateResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateFormTemplates", [{
        id,
        ...formTemplate
      }])
      context.commit("setFormTemplateUpdated", propertiesToBeUpdated)
    } else if (updateFormTemplateResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setFormTemplateUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateFormTemplateResponse.data
      })
    }
    context.commit("resetUpdatingFormTemplate", propertiesToBeUpdated)
  },
  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingFormTemplates", undefined)
    context.commit("setFormTemplates", new Array())
    context.commit("setFormTemplateAdded", undefined)
    context.commit("setFormTemplateAddError", undefined)
    context.commit("setAddingFormTemplate", undefined)
    context.commit("resetUpdatingFormTemplate")
    context.commit("resetFormTemplateUpdated")
    context.commit("resetFormTemplateUpdateError")
  }
}