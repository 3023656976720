/**
 * @file This file is used setup axios instance to make api calls
 */
import axios from "axios"
import { HTTP_STATUS_CODE, ERROR } from "@/constants"
import { getSession, getCurrentUser } from "@/utils/cognito"
import Cookies from "js-cookie"
import { getBaseURL } from "./utils"

export const GET_HTTP_CLIENT = (config, store) => {
  const httpClient       = axios.create(config)
  const themisApiBaseUrl = getBaseURL(store.rootGetters["auth/region"])

  if (config && config.baseURL && config.baseURL.includes(themisApiBaseUrl)) {
    httpClient.interceptors.request.use(async config => {
      config["content-type"] = "application/json"
      config.headers.client  = store.rootGetters["auth/clientName"]
      const isLoggedIn       = store.rootGetters["auth/isLoggedIn"]

      const idToken = Cookies.get("IdToken")
      if (idToken) {
        config.headers.Authorization = `Bearer ${idToken}`
      } else {
        if (isLoggedIn) {
          const clientUserPoolId       = store.rootGetters["auth/clientUserPoolId"]
          const clientUserPoolClientId = store.rootGetters["auth/clientUserPoolClientId"]
          const cognitoUser            = getCurrentUser(clientUserPoolId, clientUserPoolClientId)

          if (cognitoUser) {
            const session = await getSession(clientUserPoolId, clientUserPoolClientId)

            if (session) {
              config.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`
            } else {
              store.commit("auth/setLoggedIn", false, { root: true })
            }
          }
        } else {
          config.auth = {
            username: "public"
          }
        }
      }
      return config
    })
    httpClient.interceptors.response.use(response => {
      return Promise.resolve({
        status: response.status,
        data  : response.data
      })
    }, async error => {
      if (error.response) {
        if (error.response.status === HTTP_STATUS_CODE.FORBIDDEN &&
          error.response.data.name === "TokenExpiredError"
        ) {
          const refreshToken = Cookies.get("RefreshToken")
          if (refreshToken) {
            try {
              await store.dispatch(
                "auth/refreshTokens",
                undefined, {
                  root: true
                }
              )
              if (store.rootGetters["auth/isLoggedIn"]) {
                error.config.headers["Authorization"] = `Bearer ${Cookies.get("IdToken")}`
                return axios.request(error.config)
              } else {
                window.location.href = "/logout"
              }
            } catch(error) {
              window.location.href = "/logout"
            }
          } else {
            const clientUserPoolId       = store.rootGetters["auth/clientUserPoolId"]
            const clientUserPoolClientId = store.rootGetters["auth/clientUserPoolClientId"]
            const session                = await getSession(clientUserPoolId, clientUserPoolClientId)
            if (session) {
              error.config.headers["Authorization"] = `Bearer ${session.getIdToken().getJwtToken()}`
              return axios.request(error.config)
            } else {
              store.commit("auth/setLoggedIn", false, { root: true })
            }
          }
        } else {
          const response = {
            status: error.response.status,
            data  : error.response.data
          }
          if (response.status === HTTP_STATUS_CODE.CONFLICT) {
            const message = error.response.data.detail
            const field   = message.substring(message.indexOf("(") + 1, message.indexOf(")")).replace(/([-_]\w)/g, g => g[1].toUpperCase())
            response.data = {
              type: ERROR.DUPLICATE,
              field
            }
          } else if (response.status === HTTP_STATUS_CODE.PAYLOAD_TOO_LARGE) {
            // Handle payload too large error
          } else if (response.status === 401) {
            store.commit("auth/setLoggedIn", false, { root: true })
          } else {
            await store.dispatch(
              "shared/notify", {
                type: "error",
                text: "1200"
              }, {
                root: true
              }
            )
          }
          return Promise.resolve(response)
        }
      } else {
        store.commit("auth/setLoggedIn", false, { root: true })
      }
    })
  }
  return httpClient
}