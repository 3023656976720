export const REPLY_TEMPLATE = (route, name) => ([
  {
    text          : "1714",
    exact         : true,
    to            : "/reply-templates",
    useTranslation: true
  },
  {
    text          : name,
    to            : `/reply-templates/${route.params.id}`,
    useTranslation: false
  }
])