export const WORKFLOW = (route, name) => [{
  text          : "924",
  exact         : true,
  to            : "/workflows",
  useTranslation: true
}, {
  text          : name,
  exact         : false,
  to            : `/workflows/${route.params.id}`,
  useTranslation: false
}]