/**
 * @file It contains vuex roles module mutations to mutate the state variables.
 */
export default {
  setLoadingRoles: (state, loadingRoles) => {
    state.loadingRoles = loadingRoles
  },
  setRoles: (state, roles) => {
    state.roles = roles
  },
  setAddingRole: (state, addingRole) => {
    state.addingRole = addingRole
  },
  setRoleAdded: (state, roleAdded) => {
    state.roleAdded = roleAdded
  },
  setRoleAddError: (state, roleAddError) => {
    state.roleAddError = roleAddError
  },
  updateRoles: (state, roles) => {
    for (const role of roles) {
      const index = state.roles.findIndex(stateRole => stateRole?.id === role.id)
      if (index >= 0) {
        state.roles.splice(index, 1, { ...state.roles[index], ...role })
      } else {
        state.roles.push(role)
      }
    }
  },
  setUpdatingRole: (state, isUpdatingRole) => {
    state.isUpdatingRole = isUpdatingRole
  },
  updateRole: (state, role) => {
    const roleToUpdate = state.roles.find(roleToUpdate => roleToUpdate.id === role.id)
    if (role.name) {
      roleToUpdate.name = role.name
    }
    if (role.description) {
      roleToUpdate.description = role.description
    }
    if (role.permissions) {
      roleToUpdate.permissions = role.permissions
    }
  },
  setRoleUpdateError: (state, roleUpdateError) => {
    state.roleUpdateError = roleUpdateError
  },
  setIsRoleUpdated: (state, properties) => {
    for (const property of properties) {
      state.isRoleUpdated[property] = true
      setTimeout(() => {
        state.isRoleUpdated[property] = false
      }, 500)
    }
  }
}