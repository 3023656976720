import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/permissions"

export default {
  /**
   * This action will load permissions.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load permissions.
   */
  async loadPermissions(context, payload) {
    context.commit("setLoadingPermissions", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getPermissionsResponse = await httpClient.get(undefined, payload)

    if (getPermissionsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updatePermissions", [getPermissionsResponse.data])
      } else {
        context.commit("setPermissions", getPermissionsResponse.data)
      }
    }
    context.commit("setLoadingPermissions", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setPermissions", new Array())
    context.commit("setLoadingPermissions", false)
  }
}
