import { saveToLocalStorage, getFromLocalStorage } from "@/utils"

export default {
  setWorkflows: (state, workflows) => {
    state.workflows                   = JSON.parse(JSON.stringify(workflows))
    const localStorageWorkflow        = getFromLocalStorage("workflows")
    const localStorageChangedWorkflow = getFromLocalStorage("changedWorkflows")
    if (!localStorageWorkflow) {
      saveToLocalStorage("workflows", workflows)
    } else if (!localStorageChangedWorkflow) {
      saveToLocalStorage("workflows", workflows)
    } else {
      const workflowsToSave = workflows.map(workflow => {
        const changedWorkflow = localStorageChangedWorkflow[workflow.id]
        if (changedWorkflow) {
          return { ...workflow, ...localStorageWorkflow.find(localWorkflow => localWorkflow.id === workflow.id) }
        }
        return workflow
      })
      saveToLocalStorage("workflows", workflowsToSave)
    }
  },
  setLoadingWorkflows: (state, loadingWorkflows) => {
    state.loadingWorkflows = loadingWorkflows
  },
  updateWorkflows: (state, workflows) => {
    const localStorageWorkflow = getFromLocalStorage("workflows")
    for (const workflow of workflows) {
      const index = state.workflows.findIndex(stateWorkflow => stateWorkflow?.id === workflow.id)
      if (index >= 0) {
        state.workflows.splice(index, 1, { ...state.workflows[index], ...workflow })
      } else {
        state.workflows.push(workflow)
        localStorageWorkflow.push(workflow)
        saveToLocalStorage("workflows", localStorageWorkflow)
      }
    }
  },
  setAddingWorkflow: (state, addingWorkflow) => {
    state.addingWorkflow = addingWorkflow
  },
  setWorkflowAdded: (state, workflowAdded) => {
    state.workflowAdded = workflowAdded
  },
  setWorkflowAddError: (state, workflowAddError) => {
    state.workflowAddError = workflowAddError
    saveToLocalStorage("workflows", state.workflows)
  },
  setWorkflowUpdated: (state, workflowUpdated) => {
    state.workflowUpdated = workflowUpdated
  },
  setUpdatingWorkflow: (state, updatingWorkflow) => {
    state.updatingWorkflow = updatingWorkflow
  },
  setWorkflowUpdateError: (state, workflowUpdateError) => {
    state.workflowUpdateError = workflowUpdateError
  },
  setUpdatedWorkflowToLocalStorage: (state, workflowId) => {
    const localStorageWorkflow = getFromLocalStorage("workflows")
    const updatedWorkflow      = localStorageWorkflow.map(workflow => workflow.id === workflowId ?
      JSON.parse(JSON.stringify(state.workflows.find(stateWorkflow => stateWorkflow.id === workflowId))) :
      workflow
    )
    saveToLocalStorage("workflows", updatedWorkflow)
  },
  setStatusUpdated: (state, statusUpdated) => {
    state.statusUpdated = statusUpdated
  },
  setStatusError: (state, statusError) => {
    const index = state.statusErrors.findIndex(stateStatusErrors =>
      stateStatusErrors?.workflowId === statusError.workflowId)
    if (index >= 0) {
      state.statusErrors.splice(index, 1, { ...state.statusErrors[index], ...statusError })
    } else {
      state.statusErrors.push(statusError)
    }
  }
}