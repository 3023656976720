import { defaultTableHeaderClasses } from "@/constants"

export const CSV_DATA_VALIDATION = {
  headers: [
    {
      text    : "1911",
      value   : "header",
      align   : "left",
      class   : defaultTableHeaderClasses,
      sortable: false
    },
    {
      text     : "1912",
      value    : "example",
      align    : "left",
      cellClass: "text-truncate max-width-500",
      class    : defaultTableHeaderClasses,
      sortable : false
    },
    {
      text    : "1913",
      value   : "speakupFields",
      align   : "left",
      width   : "25%",
      class   : defaultTableHeaderClasses,
      sortable: false
    }
  ]
}