import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

export const beforeIssues = async (to, from) => {
  const isSameRoute = to.name === from.name
  if (!isSameRoute) {
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ISSUES))
    store.commit("shared/setBreadcrumbs", undefined)
    store.commit("transitions/setLastCreatedIssue", null)

    const loggedInUser                        = store.getters["auth/loggedInUser"]
    const loggedInUserIssuesFiltersPreference = store.getters["users/loggedInUserIssuesFiltersPreference"]

    store.dispatch("issueSearch/loadIssueSearchWithCriteria", {
      criteria: {
        searchText               : null,
        issuesFilters            : loggedInUserIssuesFiltersPreference ?? {},
        searchThroughAllIssueData: false
      }
    })
    store.dispatch("issues/loadIssues", {
      properties: "id"
    })
    store.dispatch("fields/loadFieldsV2")
    store.dispatch("users/loadUsers")
    store.dispatch("users/loadGroups", loggedInUser.id)
    store.dispatch("accesses/loadAccesses")
    store.dispatch("issueFields/loadIssueFields")
    store.dispatch("issueFieldValues/loadIssueFieldValues")
    store.dispatch("domains/loadDomains")
    store.dispatch("labels/loadLabels")
    store.dispatch("optionListItems/loadOptionListItems")
    store.dispatch("channels/loadChannels")
    store.dispatch("issueStatuses/loadIssueStatuses")
    store.dispatch("issueResolutions/loadIssueResolutions")
    store.dispatch("kpis/loadKPIs")
    store.dispatch("languages/loadLanguages")
    store.dispatch("formTemplates/loadFormTemplates", {
    }).then(() => {
      const formTemplates = store.getters["formTemplates/formTemplates"]
      if (formTemplates.length) {
        store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations", {
          formTemplateId: formTemplates.map(formTemplate => formTemplate.id).toString()
        })
      }
    })
  }
}