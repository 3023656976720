/**
 * @file It contains vuex token module mutations to mutate the state variables.
 */
export default {
  setClientName: (state, clientName) => {
    state.clientName = clientName
  },
  setClientConfigurationId: (state, clientConfigurationId) => {
    state.clientConfigurationId = clientConfigurationId
  },
  setClientUserPoolId: (state, clientUserPoolId) => {
    state.clientUserPoolId = clientUserPoolId
  },
  setClientUserPoolClientId: (state, clientUserPoolClientId) => {
    state.clientUserPoolClientId = clientUserPoolClientId
  },
  setVerificationCode: (state, verificationCode) => {
    state.verificationCode = verificationCode
  },
  setLoggingIn: (state, loggingIn) => {
    state.loggingIn = loggingIn
  },
  setLoggedIn: (state, loggedIn) => {
    state.loggedIn = loggedIn
  },
  setLoggedInUser: (state, loggedInUser) => {
    state.loggedInUser = loggedInUser
  },
  updateLoggedInUser: (state, loggedInUser) => {
    state.loggedInUser = { ...state.loggedInUser, ...loggedInUser }
  },
  setPasswordResetRequested: (state, passwordResetRequested) => {
    state.passwordResetRequested = passwordResetRequested
    if (passwordResetRequested) {
      setTimeout(() => {
        state.passwordResetRequested = false
      }, 5000)
    }
  },
  setRequestingPasswordReset: (state, requestingPasswordReset) => {
    state.requestingPasswordReset = requestingPasswordReset
  },
  setPasswordReset: (state, passwordReset) => {
    state.passwordReset = passwordReset
    if (passwordReset) {
      setTimeout(() => {
        state.passwordReset = false
      }, 5000)
    }
  },
  setInvalidCredentials: (state, invalidCredentials) => {
    state.invalidCredentials = invalidCredentials
  },
  setInvalidTotp: (state, invalidTotp) => {
    state.invalidTotp = invalidTotp
  },
  setResettingPassword: (state, resettingPassword) => {
    state.resettingPassword = resettingPassword
  },
  setNewPasswordRequired: (state, newPasswordRequired) => {
    state.newPasswordRequired = newPasswordRequired
  },
  setSecretCode: (state, secretCode) => {
    state.secretCode = secretCode
  },
  setTokenVerified: (state, tokenVerified) => {
    state.tokenVerified = tokenVerified
    if (tokenVerified) {
      setTimeout(() => {
        state.tokenVerified = false
      }, 5000)
    }
  },
  setVerifyingToken: (state, verifyingToken) => {
    state.verifyingToken = verifyingToken
  },
  setMfaEnabledForLoggedInUser: (state, mfaEnabledForLoggedInUser) => {
    state.mfaEnabledForLoggedInUser = mfaEnabledForLoggedInUser
  },
  setAssignedUserMfaPreference: (state, assignedUserMfaPreference) => {
    state.assignedUserMfaPreference = assignedUserMfaPreference
  },
  setMfaEnabled: (state, mfaEnabled) => {
    state.mfaEnabled = mfaEnabled
  },
  setTotpRequired: (state, totpRequired) => {
    state.totpRequired = totpRequired
  },
  setExceededAttempts: (state, exceededAttempts) => {
    state.exceededAttempts = exceededAttempts
  },
  setPasswordChanged: (state, passwordChanged) => {
    state.passwordChanged = passwordChanged
  },
  setChangingPassword: (state, changingPassword) => {
    state.changingPassword = changingPassword
  },
  setPasswordExpired: (state, passwordExpired) => {
    state.passwordExpired = passwordExpired
  },
  setLoggedInUserRoleTypes: (state, loggedInUserRoleTypes) => {
    state.loggedInUserRoleTypes = loggedInUserRoleTypes
  },
  setLoggedInUserDisabled: (state, loggedInUserDisabled) => {
    state.loggedInUserDisabled = loggedInUserDisabled
  },
  setRegion: (state, region) => {
    state.region = region
  }
}