import { defaultTableHeaderClasses, defaultTableItemsPerPage, defaultTableFooterOptions } from "@/constants"
export const WORKFLOWS = {
  headers: [{
    text : "680",
    align: "left",
    value: "name",
    class: defaultTableHeaderClasses
  }, {
    text : "563",
    align: "left",
    value: "issueType",
    class: defaultTableHeaderClasses
  }, {
    value   : "actions",
    align   : "left",
    class   : defaultTableHeaderClasses,
    sortable: false
  }],
  itemsPerPage: defaultTableItemsPerPage,
  footer      : defaultTableFooterOptions
}