export const TRANSITION = (route, name, transitionName) => [{
  text          : "924",
  exact         : true,
  to            : "/workflows",
  useTranslation: true
}, {
  text          : name,
  exact         : true,
  to            : `/workflows/${route.params.id}`,
  useTranslation: false
}, {
  text          : transitionName,
  exact         : false,
  to            : `/workflows/${route.params.id}/transitions?transitionName=${encodeURIComponent(route.query.transitionName)}`,
  useTranslation: false
}]