export default {
  setPreConditions: (state, preConditions) => {
    state.preConditions = preConditions
  },
  updatePreConditions: (state, preConditions) => {
    for (const preCondition of preConditions) {
      const index = state.preConditions.findIndex(statePreCondition => statePreCondition?.id === preCondition.id)
      if (index >= 0) {
        state.preConditions.splice(index, 1, { ...state.preConditions[index], ...preCondition })
      } else {
        state.preConditions.push(preCondition)
      }
    }
  }
}