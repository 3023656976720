export default {
  name: "TermsOfUseLogin",
  data: () => ({
    hasAcceptedTermsOfUse: false
  }),
  props: {
    pTermsOfUse                  : String,
    pIsUpdatingTermsOfUseAccepted: Boolean
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}