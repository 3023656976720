import store from "@/plugins/vuex"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"
import { TRANSITION } from "@/constants/bread-crumbs/transition"
import { getFromLocalStorage } from "@/utils"

export const beforeTransition = to => {
  const workflows = getFromLocalStorage("workflows")
  const workflow  = workflows.find(workflow => workflow.id === +to.params.id)

  if (!workflow) {
    return {
      name: "workflows"
    }
  }

  const transition = workflow.transitions.find(transition =>
    transition.id === +to.params.transitionId ||
      transition.name === to.query.transitionName
  )
  if (!transition) {
    return {
      name  : "workflow",
      params: {
        id: +to.params.id
      }
    }
  }
  store.dispatch("screens/loadScreens")
  store.dispatch("postFunctions/loadPostFunctions")
  store.dispatch("preConditions/loadPreConditions")
  store.dispatch("groups/loadGroups")
  store.commit("shared/setPageTitle", PAGE_TITLE_WITHOUT_TRANSLATION(transition.name))
  store.commit("shared/setBreadcrumbs", TRANSITION({
    params: {
      id: +to.params.id
    },
    query: {
      transitionName: to.query.transitionName
    }
  }, workflow.name, transition.name))
}