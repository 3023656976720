
<template lang = "pug">
  component(:is = "$route.meta.layout")
    router-view
    template(v-slot:side-panel = "")
      router-view(name="sidePanel")
</template>

<script>
import { ValidationProvider } from "vee-validate"
import Blank  from "@/layouts/blank"
import Default from "@/layouts/default"
import { mapGetters, mapActions } from "vuex"

export default {
  name      : "ThemisClient",
  components: { Blank, Default, ValidationProvider },
  data      : () => ({ }),
  computed  : {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn"
    })
  },
  watch: {
    isLoggedIn: {
      immediate: false,
      handler  : function(newValue) {
        if (!newValue && this.$route.name !== "logout") {
          this.notify({
            type: "success",
            text: "501"
          })
          this.$router.push({ name: "logout" })
        }
      }
    },
    "$route.query.microcopy": {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && process.env.VUE_APP_THEMIS_FRANKFURT_API_BASE_URL.includes("develop")) {
          this.$i18n.locale = this.$route.query.microcopy
        }
      }
    }
  },
  methods: {
    ...mapActions({
      notify: "shared/notify"
    })
  }
}
</script>