export default {
  setUpdatingScreenItem: (state, properties) => {
    for (const property of properties) {
      state.updatingScreenItem[property] = true
    }
  },
  resetScreenItemUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.screenItemUpdated[property] = false
      }
    } else {
      Object.keys(state.screenItemUpdated).forEach(
        property => state.screenItemUpdated[property] = false
      )
    }
  },
  setScreenItemUpdated: (state, properties) => {
    for (const property of properties) {
      state.screenItemUpdated[property] = true
      setTimeout(() => {
        state.screenItemUpdated[property] = false
      }, 5000)
    }
  },
  resetUpdatingScreenItem: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingScreenItem[property] = false
      }
    } else {
      Object.keys(state.updatingScreenItem).forEach(
        property => state.updatingScreenItem[property] = false
      )
    }
  },
  setRemovingScreenItem: (state, removingScreenItem) => {
    state.removingScreenItem = removingScreenItem
  },
  setScreenItemRemoved: (state, screenItemRemoved) => {
    state.screenItemRemoved = screenItemRemoved
  }
}