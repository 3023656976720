export default {
  replyTemplates                : state => state.replyTemplates,
  isLoadingReplyTemplates       : state => state.loadingReplyTemplates,
  isAddingReplyTemplate         : state => state.addingReplyTemplate,
  isReplyTemplateAdded          : state => state.replyTemplateAdded,
  replyTemplateAddError         : state => state.replyTemplateAddError,
  isUpdatingReplyTemplateName   : state => state.updatingReplyTemplate.name,
  isUpdatingReplyTemplateContent: state => state.updatingReplyTemplate.content,
  isReplyTemplateNameUpdated    : state => state.replyTemplateUpdated.name,
  isReplyTemplateContentUpdated : state => state.replyTemplateUpdated.content,
  replyTemplateNameUpdateError  : state => state.replyTemplateUpdateError,
  isRemovingReplyTemplate       : state => state.removingReplyTemplate,
  replyTemplateRemoved          : state => state.replyTemplateRemoved
}