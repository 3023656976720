export default {
  screens           : new Array(),
  loadingScreens    : false,
  addingScreen      : false,
  screenAdded       : false,
  loadingScreenItems: false,
  screenItems       : new Array(),
  screenItemsAdded  : false,
  addingScreenItems : false,
  screenAddError    : null,
  updatingScreen    : {
    name: false
  },
  screenUpdated: {
    name: false
  },
  screenUpdateError: {
    name: undefined
  }
}