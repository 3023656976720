/**
 * @file It contains vuex roles module getter methods to access store variables.
 */
import { getMapOfArrayOfObjects } from "@/utils"

export default {
  roles               : state => state.roles,
  isLoadingRoles      : state => state.loadingRoles,
  rolesMap            : state => getMapOfArrayOfObjects(state.roles, "id"),
  isAddingRole        : state => state.addingRole,
  roleAdded           : state => state.roleAdded,
  roleAddError        : state => state.roleAddError,
  isUpdatingRole      : state => state.isUpdatingRole,
  roleUpdateError     : state => state.roleUpdateError,
  isNameUpdated       : state => state.isRoleUpdated.name,
  isDescriptionUpdated: state => state.isRoleUpdated.description,
  isPermissionsUpdated: state => state.isRoleUpdated.permissions,
  isRoleDetailsUpdated: state => state.isRoleUpdated.name ||
  state.isRoleUpdated.description || state.isRoleUpdated.permissions
}