import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"
import { getFromLocalStorage, saveToLocalStorage } from "../../utils"

const path = "/v1/workflows"

export default {
  /**
   * This action will load workflows.
   * @param {*} context is the store.
   * @param {*} payload it is the filter criteria
   */
  async loadWorkflows(context, payload) {
    context.commit("setLoadingWorkflows", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getWorkflowsResponse = await httpClient.get(undefined, payload)

    if (getWorkflowsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateWorkflows", [getWorkflowsResponse.data])
      } else {
        context.commit("setWorkflows", getWorkflowsResponse.data)
      }
    }
    context.commit("setLoadingWorkflows", false)
  },

  /**
   * This action will add a workflows.
   * @param {*} context is the store.
   * @param {*} payload contains name of the workflow
   */
  async addWorkflow(context, payload) {
    context.commit("setAddingWorkflow", true)
    context.commit("setWorkflowAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getWorkflowAddResponse = await httpClient.post(undefined, payload)
    if (getWorkflowAddResponse.status === HTTP_STATUS_CODE.OK) {
      await context.dispatch("loadWorkflows")
      context.commit("setWorkflowAdded", true)
    } else if (getWorkflowAddResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setWorkflowAddError", getWorkflowAddResponse.data)
    }

    context.commit("setAddingWorkflow", false)
  },

  /**
   * This action will update workflow.
   * @param {*} context is the store.
   * @param {*} payload is the workflow to be updated.
   */
  async updateWorkflow(context, payload) {
    const workflowId = payload.id
    delete payload.id

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    context.commit("setUpdatingWorkflow", true)
    context.commit("setWorkflowUpdated", false)
    context.commit("setWorkflowUpdateError", false)

    const updateWorkflowResponse = await httpClient.put(`/${workflowId}`, payload.data)

    if (updateWorkflowResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      await context.dispatch("loadWorkflows")
      await context.dispatch("issueStatuses/loadIssueStatuses", undefined, { root: true })
      context.commit("setUpdatedWorkflowToLocalStorage", workflowId)
      context.commit("setWorkflowUpdated", true)
    } else {
      context.commit("setWorkflowUpdateError", true)
    }
    context.commit("setUpdatingWorkflow", false)
  },

  async revertChangedWorkflow(context, payload) {
    const workflowId = payload.id

    const changedWorkflows = getFromLocalStorage("changedWorkflows")
    delete changedWorkflows[workflowId]
    saveToLocalStorage("changedWorkflows", changedWorkflows)
    return changedWorkflows
  },

  async revertWorkflow(context, payload) {
    const workflowId = payload.id
    const workflows  = context.getters.workflows

    const workflowToRevert = workflows.find(workflow => workflow.id === workflowId)
    let updatedWorkflows   = workflows.map(workflow => workflow.id === workflowId
      ? workflowToRevert : workflow)

    const allWorkflowStatuses = []
    for (const workflow of workflows) {
      for (const status of workflow.statuses) {
        const existingWorkflowStatus = allWorkflowStatuses.find(workflowStatus => workflowStatus.id === status.id)
        if (!existingWorkflowStatus) {
          allWorkflowStatuses.push(status)
        }
      }
    }

    updatedWorkflows = updatedWorkflows.map(workflow => {
      return {
        ...workflow,
        statuses: workflow.statuses.map(status => {
          const existingWorkflowStatus = allWorkflowStatuses.find(allStatus => allStatus.id === status.id)
          if (existingWorkflowStatus) {
            return {
              ...status,
              name: existingWorkflowStatus.name
            }
          }
          return status
        })
      }
    })

    saveToLocalStorage("workflows", updatedWorkflows)

    return updatedWorkflows
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setWorkflows", [])
    context.commit("setLoadingWorkflows", false)
    context.commit("setAddingWorkflow", false)
    context.commit("setWorkflowAdded", false)
    context.commit("setWorkflowAddError", null)
  }
}