export default {
  automations           : state => state.automations,
  isLoadingAutomations  : state => state.loadingAutomations,
  isAutomationAdded     : state => state.automationAdded,
  isAddingAutomation    : state => state.addingAutomation,
  automationAddError    : state => state.automationAddError,
  automationUpdateError : state => state.automationUpdateError,
  isAutomationRemoved   : state => state.automationRemoved,
  isRemovingAutomation  : state => state.removingAutomation,
  isUpdatingSortingOrder: state => state.updatingAutomation.sortingOrder,
  isSortingOrderUpdated : state => state.automationUpdated.sortingOrder,
  isAutomationUpdated   : state => state.automationUpdated.name
    || state.automationUpdated.triggerConditions
    || state.automationUpdated.actionConditions
    || state.automationUpdated.stopProcessing,
  isUpdatingAutomation: state => state.updatingAutomation.name
   || state.updatingAutomation.triggerConditions
   || state.updatingAutomation.actionConditions
   || state.automationUpdated.stopProcessing
}