/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/roles"

export default {
  /**
   * This action will load roles.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load roles.
   */
  async loadRoles(context, payload) {
    context.commit("setLoadingRoles", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getRolesResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getRolesResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateRoles", [getRolesResponse.data])
      } else {
        context.commit("setRoles", getRolesResponse.data)
      }
    }
    context.commit("setLoadingRoles", false)
  },

  /**
   * This action will add custom role with permissions.
   * @param {*} context is the store.
   * @param {*} payload contains custom role details and permissions.
   */
  async addRole(context, payload) {
    context.commit("setAddingRole", true)
    context.commit("setRoleAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const payloadToApi = {
      name       : payload.name,
      description: payload.description,
      permissions: payload.permissions.map(permission => ({ id: permission.id }))
    }

    const postCustomRoleResponse = await httpClient.post(undefined, payloadToApi)

    if (postCustomRoleResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateRoles", [{
        id   : postCustomRoleResponse.data.id,
        issue: postCustomRoleResponse.data.issue,
        ...payload
      }])
      context.commit("setRoleAdded", true)
    } else if (postCustomRoleResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setRoleAddError", postCustomRoleResponse.data)
    }
    context.commit("setAddingRole", false)
  },

  async updateRole(context, payload) {
    context.commit("setUpdatingRole", true)
    const httpClient   = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)
    const payloadToApi = {
      ...(payload.name && { name: payload.name }),
      ...(payload.description && { description: payload.description }),
      ...(payload.permissions && { permissions: payload.permissions.map(permission => permission.id) })
    }

    const putResponse = await httpClient.put(`/${payload.id}`, payloadToApi)
    if (putResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateRole", payload)
      context.commit("setIsRoleUpdated", Object.keys(payloadToApi))
    } else if (putResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setRoleUpdateError", putResponse.data)
    }

    context.commit("setUpdatingRole", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setRoles", new Array())
    context.commit("setUpdatingRole", false)
    context.commit("setRoleUpdateError", null)
    context.commit("setLoadingRoles", false)
    context.commit("setAddingRole", false)
    context.commit("setRoleAdded", false)
    context.commit("setRoleAddError", false)
  }
}