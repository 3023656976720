/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/kpis"

export default {
  /**
   * This action will get KPIs response .
   * @param {*} context is the store.
   */
  async loadKPIs(context) {
    context.commit("setLoadingKpis", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getKPIsResponse = await httpClient.get()

    if (getKPIsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setKpis", getKPIsResponse.data)
    } else {
      // something went wrong while getting KPIs
    }

    context.commit("setLoadingKpis", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setKpis", new Array())

    context.commit("setLoadingKpis", undefined)
  }
}