import { defaultTableHeaderClasses } from "@/constants"

export const ROLE_ADD_PERMISSIONS = {
  headers: [{
    text    : "1862",
    value   : "permission",
    align   : "left",
    class   : defaultTableHeaderClasses,
    sortable: false
  }]
}