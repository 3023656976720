import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/issue-field-values"

export default {
  /**
   * This action will load issue field values.
   * @param {*} context it is the store.
   * @param {*} payload it is the filter criteria
   */
  async loadIssueFieldValues(context, payload) {
    context.commit("setLoadingIssueFieldValues", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getIssueFieldValuesResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getIssueFieldValuesResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateIssueFieldValues", getIssueFieldValuesResponse.data)
      } else {
        context.commit("setIssueFieldValues", getIssueFieldValuesResponse.data)
      }
    }

    context.commit("setLoadingIssueFieldValues", false)
  },

  /**
   * This action updates a issue field value.
   * @param {*} context is the store.
   * @param {*} payload contains issue field id, issue id and value to be updated.
   */
  async updateIssueFieldValue(context, payload) {
    context.commit("setUpdatingIssueFieldValue", true)
    context.commit("setIssueFieldValueUpdated", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateIssueFieldValueResponse = await httpClient.post(undefined, payload)

    if (updateIssueFieldValueResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateIssueFieldValues", updateIssueFieldValueResponse.data)
      context.commit("setIssueFieldValueUpdated", true)
    }

    context.commit("setUpdatingIssueFieldValue", false)
  }
}