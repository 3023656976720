import { defaultTableHeaderClasses, defaultTableFooterOptions, defaultTableItemsPerPage } from "@/constants"

export const CHANNELS =  {
  headers: [{
    text : "100",
    align: "left",
    value: "channelName",
    class: defaultTableHeaderClasses
  }, {
    text : "70",
    align: "left",
    value: "domain",
    class: defaultTableHeaderClasses
  }, {
    text : "71",
    align: "left",
    value: "channelUrl",
    class: defaultTableHeaderClasses
  }, {
    text : "387",
    align: "left",
    value: "channelOrganisationCode",
    class: defaultTableHeaderClasses
  }, {
    text : "102",
    align: "left",
    value: "channelStatus",
    class: defaultTableHeaderClasses
  }, {
    text : "",
    align: "left",
    value: "qrCodeLink",
    class: defaultTableHeaderClasses
  }],
  loadingText : "72",
  noDataText  : "73",
  noResultText: "74",
  itemsPerPage: defaultTableItemsPerPage,
  footer      : {
    ...defaultTableFooterOptions,
    itemsPerPageText: "75"
  }
}