/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/issue-resolutions"

export default {
  /**
   * This action will load issueResolutions.
   * @param {*} context is the store.
   */
  async loadIssueResolutions(context) {
    context.commit("setLoadingIssueResolutions", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getIssueResolutionsResult = await httpClient.get()

    if (getIssueResolutionsResult.status === HTTP_STATUS_CODE.OK) {
      context.commit("setIssueResolutions", getIssueResolutionsResult.data)
    } else {
      // something went wrong while getting issues
    }

    context.commit("setLoadingIssueResolutions", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingIssueResolutions", undefined)
    context.commit("setIssueResolutions", new Array())
  }
}