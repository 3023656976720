/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/domains"

export default {
  /**
   * This action will load domains.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load domains.
   */
  async loadDomains(context, payload) {
    context.commit("setLoadingDomains", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getDomainsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getDomainsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateDomains", [getDomainsResponse.data])
      } else {
        context.commit("setDomains", getDomainsResponse.data)
      }
    } else {
      // something went wrong while getting domains
    }

    context.commit("setLoadingDomains", false)
  },

  /**
   * This action will add a domain.
   * @param {*} context is the store.
   */
  async addDomain(context, payload) {
    context.commit("setAddingDomain", true)
    context.commit("setDomainAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postDomainResponse = await httpClient.post(undefined, payload)

    if (postDomainResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateDomains", [postDomainResponse.data])
      context.commit("setDomainAdded", true)
    } else if (postDomainResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setDomainAddError", postDomainResponse.data)
    } else {
      // something went wrong while adding a domain
    }

    context.commit("setAddingDomain", false)
  },

  /**
   * This action will load a particular domain.
   * @param {*} context it is the store.
   * @param {*} payload it is id of the domain to be loaded
   */
  async loadDomain(context, payload) {
    context.commit("setLoadingDomains", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getDomainResponse = await httpClient.get(`/${payload}`)

    if (getDomainResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateDomains", [getDomainResponse.data])
    } else {
      // something went wrong while getting an domain
    }

    context.commit("setLoadingDomains", false)
  },

  /**
   * This action updates summary of an domain.
   * @param {*} context is the store.
   * @param {*} domain id and summary of an domain.
   */
  async updateDomain(context, domain) {
    const id = domain.id
    delete domain.id
    const propertiesToBeUpdated = Object.keys(domain)
    for (const property of propertiesToBeUpdated) {
      if (domain[property] === undefined) {
        domain[property] = null
      }
    }
    context.commit("setUpdatingDomain", propertiesToBeUpdated)
    context.commit("resetDomainUpdated", propertiesToBeUpdated)
    context.commit("resetDomainUpdateError", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateDomainResponse = await httpClient.put(`/${id}`, domain)

    if (updateDomainResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateDomains", [{
        id,
        ...domain
      }])
      context.commit("setDomainUpdated", propertiesToBeUpdated)
    } else if (updateDomainResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setDomainUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateDomainResponse.data
      })
    } else if (updateDomainResponse.status === HTTP_STATUS_CODE.BAD_REQUEST){
      context.commit("setDomainUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateDomainResponse.data
      })
    }

    context.commit("resetUpdatingDomain", propertiesToBeUpdated)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setDomains", new Array())
    context.commit("setLoadingDomains", undefined)
    context.commit("setAddingDomain", undefined)
    context.commit("setDomainAdded", undefined)
    context.commit("setDomainAddError", undefined)
    context.commit("resetUpdatingDomain")
    context.commit("resetDomainUpdated")
    context.commit("resetDomainUpdateError")
  }
}
