export default {
  setScreens: (state, screens) => {
    state.screens = screens
  },
  setLoadingScreens: (state, loadingScreens) => {
    state.loadingWorkflows = loadingScreens
  },
  setScreenAddError: (state, screenAddError) => {
    state.screenAddError = screenAddError
  },
  setScreenItems: (state, screenItems) => {
    state.screenItems = screenItems
  },
  setLoadingScreenItems: (state, loadingScreenItems) => {
    state.loadingScreenItems = loadingScreenItems
  },
  updateScreens: (state, screens) => {
    for (const screen of screens) {
      const index = state.screens.findIndex(stateScreen => stateScreen?.id === screen.id)
      if (index >= 0) {
        state.screens.splice(index, 1, { ...state.screens[index], ...screen })
      } else {
        state.screens.push(screen)
      }
    }
  },
  updateScreenItems: (state, screenItems) => {
    for (const screenItem of screenItems){
      const indexOfScreenItem = state.screenItems.findIndex(stateScreen => stateScreen?.id === screenItem.id)
      const screenItemInStore = state.screenItems[indexOfScreenItem]

      const screenItemsToBeUpdated = [{
        index: indexOfScreenItem,
        screenItem
      }]

      if (screenItemInStore && screenItem.sortingOrder) {
        for (const [index, stateScreenItem] of state.screenItems.entries()) {
          if (stateScreenItem.id !== screenItem.id) {
            if (stateScreenItem.sortingOrder < screenItemInStore.sortingOrder) {
              if (stateScreenItem.sortingOrder >= screenItem.sortingOrder) {
                screenItemsToBeUpdated.push({
                  index,
                  screenItem: {
                    sortingOrder: stateScreenItem.sortingOrder + 1
                  }
                })
              }
            } else if (stateScreenItem.sortingOrder > screenItemInStore.sortingOrder) {
              if (stateScreenItem.sortingOrder <= screenItem.sortingOrder) {
                screenItemsToBeUpdated.push({
                  index,
                  screenItem: {
                    sortingOrder: stateScreenItem.sortingOrder - 1
                  }
                })
              }
            }
          }
        }
      }

      for (const screenItemToBeUpdated of screenItemsToBeUpdated) {
        if (screenItemToBeUpdated.index >= 0) {
          state.screenItems.splice(screenItemToBeUpdated.index, 1, {
            ...state.screenItems[screenItemToBeUpdated.index],
            ...screenItemToBeUpdated.screenItem
          })
        } else {
          state.screenItems.push(screenItemToBeUpdated.screenItem)
        }
      }
    }
  },
  setAddingScreenItems: (state, addingScreenItems) => {
    state.addingScreenItems = addingScreenItems
  },
  setScreenItemsAdded: (state, screenItemsAdded) => {
    state.screenItemsAdded = screenItemsAdded
  },
  setAddingScreen: (state, addingScreen) => {
    state.addingScreen = addingScreen
  },
  setScreenAdded: (state, screenAdded) => {
    state.screenAdded = screenAdded
  },
  setUpdatingScreen: (state, properties) => {
    for (const property of properties) {
      state.updatingScreen[property] = true
    }
  },
  resetScreenUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.screenUpdated[property] = false
      }
    } else {
      Object.keys(state.screenUpdated).forEach(property => state.screenUpdated[property] = false)
    }
  },
  resetScreenUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.screenUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.screenUpdateError).forEach(property => state.screenUpdateError[property] = undefined)
    }
  },
  setScreenUpdated: (state, properties) => {
    for (const property of properties) {
      state.screenUpdated[property] = true
      setTimeout(() => {
        state.screenUpdated[property] = false
      }, 5000)
    }
  },
  setScreenUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.screenUpdateError[property] = error
    }
  },
  resetUpdatingScreen: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingScreen[property] = false
      }
    } else {
      Object.keys(state.updatingScreen).forEach(property => state.updatingScreen[property] = false)
    }
  },
  removeScreenItem: (state, idOfScreenItemToBeRemoved) => {
    const index = state.screenItems.findIndex(screenItem => screenItem.id === idOfScreenItemToBeRemoved)
    state.screenItems.splice(index, 1)
  }
}