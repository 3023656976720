import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to roles page.
 */
export const beforeRoles = async () => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ROLES))
  store.commit("shared/setBreadcrumbs", undefined)
  store.dispatch("permissions/loadPermissions")
  await store.dispatch("roles/loadRoles")
}
