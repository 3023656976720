/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE, IDP_LOGIN_URL } from "@/constants"
import store from "@/plugins/vuex"
import { CONFIGURATIONS } from "@/constants"
import { format }   from "@/utils"
import crypto from "crypto"
import { getBaseURL } from "@/utils"

const path = "/v1/configurations"

export default {
  /**
   * This action will load configurations.
   * @param {*} context is the store.
   */
  async loadConfigurations(context) {
    context.commit("setLoadingConfigurations", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getConfigurationsResponse = await httpClient.get()

    if (getConfigurationsResponse.status === HTTP_STATUS_CODE.OK) {
      const configurations = getConfigurationsResponse.data
      context.commit("setConfigurations", configurations )
      const clientConfigurationId = configurations.find(
        configuration => configuration.key === CONFIGURATIONS.ID
      )?.value

      context.commit("auth/setClientConfigurationId", clientConfigurationId, { root: true })
    } else {
      // something went wrong while getting configurations
    }

    context.commit("setLoadingConfigurations", false)
  },

  /**
   * This action updates configurations.
   * @param {*} context is the store.
   * @param {*} configuration contains details of specific configuration.
   */
  async updateConfiguration(context, configuration) {
    const id = configuration.id
    delete configuration.id
    const propertiesToBeUpdated = Object.keys(configuration)
    for (const property of propertiesToBeUpdated) {
      if (configuration[property] === undefined) {
        configuration[property] = null
      }
    }
    context.commit("setUpdatingConfiguration", propertiesToBeUpdated)
    context.commit("resetConfigurationUpdated", propertiesToBeUpdated)
    context.commit("resetConfigurationUpdateError", propertiesToBeUpdated)

    const httpClient         = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)
    const updateUserResponse = await httpClient.put(`/${id}`, configuration)

    if (updateUserResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateConfigurations", [{
        id,
        ...configuration
      }])
      context.commit("setConfigurationUpdated", propertiesToBeUpdated)
    } else if (updateUserResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setConfigurationUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateUserResponse.data
      })
    } else {
      // something went wrong while updating an user
    }

    context.commit("resetUpdatingConfiguration", propertiesToBeUpdated)
  },

  /**
   * This action will load configurations for sso.
   * @param {*} context is the store.
   */
  async loadDefaultSsoConfiguration(context) {
    context.commit("setLoadingDefaultSsoConfiguration", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getSsoConfigurationResponse = await httpClient.get("/sso")

    if (getSsoConfigurationResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setDefaultSsoConfiguration", getSsoConfigurationResponse.data)
    }

    context.commit("setLoadingDefaultSsoConfiguration", false)
  },

  /**
   * This action will save configurations for sso.
   * @param {*} context is the store.
   * @param {*} payload contains details of specific defaultSsoConfiguration.
   */
  async saveDefaultSsoConfiguration(context, payload) {
    context.commit("setSavingDefaultSsoConfiguration", true)
    context.commit("setDefaultSsoConfigurationSaved", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postSsoConfigurationResponse = await httpClient.post("/sso", payload)

    if (postSsoConfigurationResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setDefaultSsoConfiguration", payload)
      context.commit("setDefaultSsoConfigurationSaved", true)
      store.dispatch("configurations/loadConfigurations")
    } else if(postSsoConfigurationResponse.status === HTTP_STATUS_CODE.FAILED_DEPENDENCY){
      context.commit("setDefaultSsoConfigurationError", postSsoConfigurationResponse.data)
    }

    context.commit("setSavingDefaultSsoConfiguration", false)
  },

  /**
   * This action will start test IDP login process
   * @param {*} context context is the store
   */
  async testDefaultSsoLogin(context) {
    const verificationCode       = crypto.randomBytes(16).toString("hex")
    const domainUrl              = format(
      process.env.VUE_APP_COGNITO_WEB_DOMAIN,
      context.rootGetters["auth/clientConfigurationId"],
      context.rootGetters["auth/region"])
    const clientUserPoolClientId = context.rootGetters["auth/clientUserPoolClientId"]
    const redirectUrl            = format(process.env.VUE_APP_LOGIN_CALLBACK_URL, context.rootGetters["auth/clientName"])

    window.open(IDP_LOGIN_URL(
      domainUrl,
      clientUserPoolClientId,
      redirectUrl,
      verificationCode
    ))
  },

  /**
   * This action will enable logging in using default sso option.
   * @param {*} context is the store
   */
  async enableDefaultSsoLogin(context) {
    context.commit("setEnablingDefaultSsoLogin", true)
    context.commit("setDefaultSsoLoginEnabled", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postSsoConfigurationResponse = await httpClient.post("/sso/login", {
      enable: true
    })

    if (postSsoConfigurationResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setDefaultSsoLoginEnabled", true)
      store.dispatch("configurations/loadConfigurations")
    }

    context.commit("setEnablingDefaultSsoLogin", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingConfigurations", undefined)
    context.commit("setConfigurations", new Array())
    context.commit("setLoadingDefaultSsoConfiguration", undefined)
    context.commit("setLoadingDefaultSsoConfiguration", false)
    context.commit("setSavingDefaultSsoConfiguration", false)
    context.commit("setDefaultSsoConfigurationSaved", false)
    context.commit("setDefaultSsoConfiguration", undefined)
    context.commit("setDefaultSsoConfigurationError", undefined)
    context.commit("setEnablingDefaultSsoLogin", undefined)
    context.commit("setDefaultSsoLoginEnabled", undefined)
  }
}