export default {
  updatingScreenItem: {
    mandatory   : false,
    sortingOrder: false
  },
  screenItemUpdated: {
    mandatory   : false,
    sortingOrder: false
  },
  removingScreenItem: false,
  screenItemRemoved : false
}