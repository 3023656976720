export default {
  loadingIssueDocuments: undefined,
  issueDocuments       : new Array(),
  addingIssueDocument  : undefined,
  removingIssueDocument: undefined,
  issueDocumentRemoved : undefined,
  updatingIssueDocument: {
    value      : false,
    folderId   : false,
    description: false
  },
  accessInformation   : new Object(),
  issueDocumentUpdated: {
    value      : false,
    folderId   : false,
    description: false
  },
  issueDocumentAdded: false
}