export default {
  isLoadingFormTemplateConfigurations: state => state.loadingFormTemplateConfigurations,
  formTemplateConfigurations         : state => state.formTemplateConfigurations,
  isAddingFormTemplateConfigurations : state => state.addingFormTemplateConfigurations,
  formTemplateConfigurationsAdded    : state => state.formTemplateConfigurationsAdded,
  isUpdatingMandatory                : state => state.updatingFormTemplateConfiguration.mandatory,
  isMandatoryUpdated                 : state => state.formTemplateConfigurationUpdated.mandatory,
  isUpdatingSortingOrder             : state => state.updatingFormTemplateConfiguration.sortingOrder,
  isSortingOrderUpdated              : state => state.formTemplateConfigurationUpdated.sortingOrder,
  isRemovingFormTemplateConfiguration: state => state.removingFormTemplateConfiguration,
  formTemplateConfigurationRemoved   : state => state.formTemplateConfigurationRemoved,
  isAddingFieldConditions            : state => state.addingFieldConditions,
  fieldConditionsAdded               : state => state.fieldConditionsAdded,
  isRemovingFieldConditions          : state => state.removingFieldConditions,
  fieldConditionsRemoved             : state => state.fieldConditionsRemoved
}