export default {
  workflows          : state => state.workflows,
  isLoadingWorkflows : state => state.loadingWorkflows,
  isAddingWorkflow   : state => state.addingWorkflow,
  isWorkflowAdded    : state => state.workflowAdded,
  workflowAddError   : state => state.workflowAddError,
  isUpdatingWorkflow : state => state.updatingWorkflow,
  isWorkflowUpdated  : state => state.workflowUpdated,
  workflowUpdateError: state => state.workflowUpdateError,
  isStatusUpdated    : state => state.statusUpdated,
  hasStatusErrors    : state => state.statusErrors
}