import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/workflow-associations"

export default {
  /**
   * This action will load workflowAssociations.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load workflowAssociations.
   */
  async loadWorkflowAssociations(context) {
    context.commit("setLoadingWorkflowAssociations", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getWorkflowAssociationsResponse = await httpClient.get()

    if (getWorkflowAssociationsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setWorkflowAssociations", getWorkflowAssociationsResponse.data)
    }
    context.commit("setLoadingWorkflowAssociations", false)
  },

  /**
   * This action will add a workflowAssociation.
   * @param {*} context is the store.
   * @param {*} payload contains name of the workflowAssociation.
   */
  async addWorkflowAssociation(context, payload) {
    context.commit("setAddingWorkflowAssociation", true)
    context.commit("setWorkflowAssociationAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postWorkflowAssociationAddResponse = await httpClient.post(undefined, payload)
    if (postWorkflowAssociationAddResponse.status === HTTP_STATUS_CODE.OK) {
      const workflows = []
      for (const workflowAssociation of postWorkflowAssociationAddResponse.data) {
        workflows.push({
          id               : workflowAssociation.id,
          workflowId       : workflowAssociation.workflowId,
          issueTypeId      : workflowAssociation.issueTypeId,
          migrationComplete: workflowAssociation.migrationComplete,
          createdAt        : workflowAssociation.createdAt,
          updatedAt        : workflowAssociation.updatedAt
        })
      }
      context.commit("updateWorkflowAssociations", workflows)
      context.commit("setWorkflowAssociationAdded", true)
    } else if (postWorkflowAssociationAddResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setWorkflowAssociationAddError", postWorkflowAssociationAddResponse.data)
    }
    context.commit("setAddingWorkflowAssociation", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setWorkflowAssociations", new Array())
    context.commit("setLoadingWorkflowAssociations", false)
    context.commit("setAddingWorkflowAssociation", false)
    context.commit("setWorkflowAssociationAdded", false)
    context.commit("setWorkflowAssociationAddError", null)
  }
}