export default {
  isLoadingFolders  : state => state.loadingFolders,
  folders           : state => state.folders,
  isAddingFolder    : state => state.addingFolder,
  isFolderAdded     : state => state.folderAdded,
  isRemovingFolder  : state => state.removingFolder,
  isFolderRemoved   : state => state.folderRemoved,
  isUpdatingParentId: state => state.updatingFolder.parentId,
  isParentIdUpdated : state => state.folderUpdated.parentId,
  isUpdatingName    : state => state.updatingFolder.name,
  isNameUpdated     : state => state.folderUpdated.name
}