export default {
  setTransitionDone: (state, transitionDone) => {
    state.transitionDone = transitionDone
  },
  setTransitionInProgress: (state, transitionInProgress) => {
    state.transitionInProgress = transitionInProgress
  },
  setLastCreatedIssue: (state, lastCreatedIssue) => {
    state.lastCreatedIssue = lastCreatedIssue
  }
}