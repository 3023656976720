/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/logs"

export default {
  /**
   * This action will load logs.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load logs.
   */
  async loadLogs(context, payload) {
    context.commit("setLoadingLogs", true)
    context.commit("setLoadingLogsError", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getLogsResult = await httpClient.get(undefined, {
      params: payload
    })

    if (getLogsResult.status === HTTP_STATUS_CODE.OK) {
      context.commit("setLogs", getLogsResult.data)
    } else if (getLogsResult.status === HTTP_STATUS_CODE.PAYLOAD_TOO_LARGE) {
      context.commit("setLoadingLogsError", true)
    }
    context.commit("setLoadingLogs", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingLogs", undefined)
    context.commit("setLogs", new Array())
    context.commit("setLoadingLogsError", false)
  }
}