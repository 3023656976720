export default {
  isLoadingMessageItems        : state => state.loadingMessageItems,
  isDownloadingFile            : state => state.downloadingFile,
  messageItems                 : state => state.messageItems,
  isMessageItemAdded           : state => state.messageItemAdded,
  isAddingMessageItem          : state => state.addingMessageItem,
  isUpdatingValue              : state => state.updatingMessageItem.value,
  isValueUpdated               : state => state.messageItemUpdated.value,
  isUpdatingFolderId           : state => state.updatingMessageItem.folderId,
  isFolderIdUpdated            : state => state.messageItemUpdated.folderId,
  isMessageItemHumanTranslated : state => state.messageItemHumanTranslated,
  isHumanTranslatingMessageItem: state => state.humanTranslatingMessageItem
}