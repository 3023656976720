import store from "@/plugins/vuex"
import { PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { ROLE } from "../constants/bread-crumbs/role"
import { PAGE_TITLES } from "../constants/page-titles"

export const beforeRole = async (to, from) => {
  if (!from || from.name !== "role") {
    const role = store.getters["roles/roles"].find(role => role.id === +to.params.id)
    if (role && role.systemDefault) {
      return { name: "roles" }
    }
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ROLE))
    await store.dispatch("permissions/loadPermissions")
    store.commit("shared/setBreadcrumbs", ROLE(to, role.name))
  }
}
