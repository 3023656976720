import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/email-subscriptions"

export default {
  /**
   * This action will load emailSubscriptions.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load emailSubscriptions.
   */
  async loadEmailSubscriptions(context, payload) {
    context.commit("setLoadingEmailSubscriptions", true)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getEmailSubscriptionsResponse = await httpClient.get(undefined, payload)

    if (getEmailSubscriptionsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateEmailSubscriptions", [getEmailSubscriptionsResponse.data])
      } else {
        context.commit("setEmailSubscriptions", getEmailSubscriptionsResponse.data)
      }
    }

    context.commit("setLoadingEmailSubscriptions", false)
  },

  /**
   * This action updates email subscription.
   * @param {*} context is the store.
   * @param {*} emailSubscription id and subscribed of a emailSubscription.
   */
  async updateEmailSubscription(context, emailSubscription) {
    const { id, subscribed } = emailSubscription

    context.commit("setUpdatingEmailSubscription", true)
    context.commit("setEmailSubscriptionUpdated", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateEmailSubscriptionResponse = await httpClient.put(`/${id}`, { subscribed })

    if (updateEmailSubscriptionResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateEmailSubscriptions", [emailSubscription])
      context.commit("setEmailSubscriptionUpdated", true)
    }

    context.commit("setUpdatingEmailSubscription", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setEmailSubscriptions", new Array())
    context.commit("setLoadingEmailSubscriptions", undefined)
    context.commit("setUpdatingEmailSubscription", undefined)
    context.commit("setEmailSubscriptionUpdated", undefined)
  }
}