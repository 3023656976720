import store from "@/plugins/vuex"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"
import { SCREEN } from "@/constants/bread-crumbs/screen"

export const beforeScreen = async (to, from) => {
  if ((from.name === "screen" || from.name === "screen-item-side-panel") &&
    to.name === "screen-item-side-panel") {
    return
  } else if (from.name === "screen-item-side-panel" && to.name === "screen") {
    return
  } else {
    await store.dispatch("screens/loadScreen", +to.params.id)
    const screens = store.getters["screens/screens"]
    const screen  = screens.find(screen => screen.id === +to.params.id)
    if (!screen) {
      return "screens"
    }
    const loadScreenItemsPromise = store.dispatch("screens/loadScreenItems", {
      screenId: +to.params.id
    })
    if (to.name === "screen-item-side-panel") {
      await loadScreenItemsPromise
      const screenItems = store.getters["screens/screenItems"]
      const screenItem  = screenItems.find(item => item.id === +to.params.itemId)
      if (!screenItem) {
        return "screens"
      }
    }
    store.dispatch("issueFields/loadIssueFields")
    store.commit("shared/setPageTitle", PAGE_TITLE_WITHOUT_TRANSLATION(screen.name))
    store.commit("shared/setBreadcrumbs", SCREEN(to, screen.name))
    store.dispatch("optionLists/loadOptionLists")
    store.dispatch("fields/loadFieldsV2")
  }
}