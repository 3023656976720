import { defaultTableHeaderClasses, defaultTableFooterOptions, defaultTableItemsPerPage } from "@/constants"

export const DOMAIN_CHANNELS =  {
  headers: [{
    text : "100",
    align: "left",
    value: "name",
    class: defaultTableHeaderClasses
  }, {
    text : "387",
    align: "left",
    value: "organisationCode",
    class: defaultTableHeaderClasses
  }, {
    text : "1035",
    align: "left",
    value: "url",
    class: defaultTableHeaderClasses
  }, {
    align   : "left",
    value   : "actions",
    class   : defaultTableHeaderClasses,
    sortable: false
  }],
  itemsPerPage: defaultTableItemsPerPage,
  footer      : defaultTableFooterOptions
}