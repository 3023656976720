import { defaultTableHeaderClasses } from "@/constants"
export const ROLE_PERMISSIONS = {
  headers: [{
    text    : "1895",
    align   : "left",
    value   : "name",
    class   : defaultTableHeaderClasses,
    sortable: false
  },
  {
    align: "left",
    value: "data-table-select",
    class: defaultTableHeaderClasses
  }]
}