/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/issue-statuses"

export default {
  /**
   * This action will load issue statuses.
   * @param {*} context is the store.
   */
  async loadIssueStatuses(context) {
    context.commit("setLoadingIssueStatuses", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getIssueStatusesResult = await httpClient.get()

    if (getIssueStatusesResult.status === HTTP_STATUS_CODE.OK) {
      context.commit("setIssueStatuses", getIssueStatusesResult.data)
    } else {
      // something went wrong while getting issues
    }

    context.commit("setLoadingIssueStatuses", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingIssueStatuses", undefined)
    context.commit("setIssueStatuses", new Array())
  }
}