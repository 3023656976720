export default {
  automations       : new Array(),
  loadingAutomations: false,
  addingAutomation  : false,
  automationAdded   : false,
  automationAddError: undefined,
  updatingAutomation: {
    name             : false,
    actionConditions : false,
    triggerConditions: false,
    sortingOrder     : false,
    stopProcessing   : false
  },
  automationUpdated: {
    name             : false,
    actionConditions : false,
    triggerConditions: false,
    sortingOrder     : false,
    stopProcessing   : false
  },
  automationUpdateError: undefined,
  removingAutomation   : false,
  automationRemoved    : false
}