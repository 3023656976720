/**
 * @file It sets up internationalization for this application use vue-i18n
 */
import Vue from "vue"
import VueI18n from "vue-i18n"

Vue.use(VueI18n)

function loadLocaleMessages() {
  let locales
  if (process.env.VUE_APP_THEMIS_FRANKFURT_API_BASE_URL.includes("develop")){
    locales = require.context("@/locales", true, /.*.json$/i)
  } else {
    locales = require.context("@/locales", true, /^((?!description|microcopy).)*.json$/i)
  }
  const messages = new Object()
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale     = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale        : "en-GB",
  fallbackLocale: "en-GB",
  messages      : loadLocaleMessages()
})