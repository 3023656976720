export default {
  loggedInUserPolicies              : state => state.loggedInUserPolicies,
  issueRemoveCommentPolicies        : state => state.issueRemoveCommentPolicies,
  issueAddCommentPolicies           : state => state.issueAddCommentPolicies,
  issueUpdatePolicies               : state => state.issueUpdatePolicies,
  issueViewPolicies                 : state => state.issueViewPolicies,
  issueAddDocumentsPolicies         : state => state.issueAddDocumentsPolicies,
  issueDocumentRemovePolicies       : state => state.issueDocumentRemovePolicies,
  reportViewPolicies                : state => state.reportViewPolicies,
  reportUpdatePolicies              : state => state.reportUpdatePolicies,
  reportContentClearPolicies        : state => state.reportContentClearPolicies,
  reportAddMessagesPolicies         : state => state.reportAddMessagesPolicies,
  messageUpdatePolicies             : state => state.messageUpdatePolicies,
  messageRemovePolicies             : state => state.messageRemovePolicies,
  messageItemAddTranslationsPolicies: state => state.messageItemAddTranslationsPolicies,
  translationUpdatePolicies         : state => state.translationUpdatePolicies,
  issueAddFormInstancesPolicies     : state => state.issueAddFormInstancesPolicies,
  formInstanceUpdatePolicies        : state => state.formInstanceUpdatePolicies,
  formInstanceRemovePolicies        : state => state.formInstanceRemovePolicies,
  issueUpdateIssueFieldValuesPolices: state => state.issueUpdateIssueFieldValuesPolices,
  issuePrintPolicies                : state => state.issuePrintPolicies,
  issueLinkAddPolicies              : state => state.issueLinkAddPolicies,
  issueLinkRemovePolicies           : state => state.issueLinkRemovePolicies,
  issueAddTaskPolicies              : state => state.issueAddTaskPolicies,
  issueAddFoldersPolicies           : state => state.issueAddFoldersPolicies,
  issueDocumentUpdatePolicies       : state => state.issueDocumentUpdatePolicies,
  messageItemUpdatePolicies         : state => state.messageItemUpdatePolicies,
  folderUpdatePolicies              : state => state.folderUpdatePolicies,
  folderRemovePolicies              : state => state.folderRemovePolicies
}