import store from "@/plugins/vuex"
import { CHANNEL } from "@/constants/bread-crumbs/channel"
import { DOMAIN_CHANNEL } from "@/constants/bread-crumbs/domain-channel"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { BOOLEAN } from "../constants"
import { PAGES } from "../constants"

/**
 * This method will be called before navigating to channel page.
 * @param {*} to route to be navigated to.
 */
export const beforeChannel = async (to, from) => {
  if (from.name !== "channel" || !(PAGES.CHANNEL.includes(to.hash) || PAGES.CHANNEL.includes(from.hash))) {
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.CHANNEL))
    if (to.name === "domain-channel")  {
      store.commit("shared/setBreadcrumbs", DOMAIN_CHANNEL(to))
    } else {
      store.commit("shared/setBreadcrumbs", CHANNEL(to))
    }
    store.commit("channels/setDraftPrivacyPolicyTranslations", new Object())
    store.commit("channels/setPrivacyPolicyTranslations", new Object())
    store.commit("channels/resetUpdatingChannel")
    store.commit("channels/resetChannelUpdated")
    store.dispatch("configurations/loadConfigurations")
    store.dispatch("translationPreferences/loadTranslationPreferences")
    store.dispatch("domains/loadDomains")
    store.dispatch("organisationCodes/loadAvailableOrganisationCodes")
    await store.dispatch("channels/loadChannel", +to.params.id)
    const channels = store.getters["channels/channels"]
    const channel  = channels.find(channel => channel.id === +to.params.id)
    if (!channel && (!to.params.domainId || channel.domainId !== +to.params.domainId)) {
      return "dashboard"
    }

    if (channel.privacyPolicyText || channel.draftPrivacyPolicyText) {
      store.dispatch("channels/loadPrivacyPolicyTranslations", {
        id    : channel.id,
        draft : true,
        actual: true
      })
    }
    store.dispatch("channels/loadChannelFormTemplates", +to.params.id)
    store.dispatch("formTemplates/loadFormTemplates", {
      reportForm: BOOLEAN.TRUE
    })
    store.dispatch("fields/loadFieldsV2")
    store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations")
    store.dispatch("channels/loadTranslationConfigurations", channel.id)
  }
}
