import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to exceptions page.
 */
export const beforeLogs = async () => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.LOGS))
  store.commit("shared/setBreadcrumbs", undefined)
  store.dispatch("users/loadUsers")
  store.dispatch("groups/loadGroups")
  store.dispatch("roles/loadRoles")
}