import { defaultTableHeaderClasses, defaultTableFooterOptions, defaultTableItemsPerPage } from "@/constants"

export const TRANSITION_PRE_CONDITIONS =  {
  headers: [{
    text : "1128",
    align: "left",
    value: "ruleName",
    class: defaultTableHeaderClasses
  }, {
    value   : "actions",
    align   : "right",
    class   : defaultTableHeaderClasses,
    sortable: false
  }],
  itemsPerPage: defaultTableItemsPerPage,
  footer      : defaultTableFooterOptions
}