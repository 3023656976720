import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/post-functions"

export default {
  /**
   * This action will load post functions.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load post functions.
   */
  async loadPostFunctions(context, payload) {
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getPostFunctionsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getPostFunctionsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updatePostFunctions", [getPostFunctionsResponse.data])
      } else {
        context.commit("setPostFunctions", getPostFunctionsResponse.data)
      }
    }
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setPostFunctions", new Array())
  }
}
