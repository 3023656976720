import store from "@/plugins/vuex"
import { REPLY_TEMPLATE } from "@/constants/bread-crumbs/reply-template"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to report template page.
 * @param {*} to route to be navigated to.
 */
export const beforeReplyTemplate = async to => {
  await store.dispatch("replyTemplates/loadReplyTemplate", +to.params.id)
  const replyTemplates = store.getters["replyTemplates/replyTemplates"]

  const replyTemplate = replyTemplates.find(replyTemplate => replyTemplate.id === +to.params.id)
  if (!replyTemplate) {
    return "dashboard"
  }
  store.commit("shared/setPageTitle", PAGE_TITLE_WITHOUT_TRANSLATION(replyTemplate.name))
  store.commit("shared/setBreadcrumbs", REPLY_TEMPLATE(to, replyTemplate.name))
}