import { defaultTableHeaderClasses, defaultTableFooterOptions, defaultTableItemsPerPage } from "@/constants"

export const USERS = {
  headers: [{
    text    : "125",
    align   : "left",
    value   : "name",
    class   : defaultTableHeaderClasses,
    sortable: true
  }, {
    text    : "126",
    align   : "left",
    value   : "email",
    class   : defaultTableHeaderClasses,
    sortable: true
  }, {
    text    : "127",
    align   : "left",
    value   : "status",
    class   : defaultTableHeaderClasses,
    sortable: true
  }, {
    text    : "128",
    align   : "left",
    value   : "groups",
    class   : defaultTableHeaderClasses,
    sortable: false
  }],
  loading     : true,
  loadingText : "132",
  noDataText  : "133",
  noResultText: "134",
  itemsPerPage: defaultTableItemsPerPage,
  footer      : {
    ...defaultTableFooterOptions,
    itemsPerPageText: "135"
  }
}