export default {
  replyTemplates       : new Array(),
  loadingReplyTemplates: false,
  addingReplyTemplate  : false,
  replyTemplateAdded   : false,
  replyTemplateAddError: undefined,
  updatingReplyTemplate: {
    name   : false,
    content: false
  },
  replyTemplateUpdated: {
    name   : false,
    content: false
  },
  replyTemplateUpdateError: undefined,
  removingReplyTemplate   : false,
  replyTemplateRemoved    : false
}