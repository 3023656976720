export default {
  setLoadingFolders: (state, loadingFolders) => {
    state.loadingFolders = loadingFolders
  },
  setFolders: (state, folders) => {
    state.folders = folders
  },
  setAddingFolder: (state, addingFolder) => {
    state.addingFolder = addingFolder
  },
  setFolderAdded: (state, folderAdded) => {
    state.folderAdded = folderAdded
  },
  setRemovingFolder: (state, removingFolder) => {
    state.removingFolder = removingFolder
  },
  setFolderRemoved: (state, folderRemoved) => {
    state.folderRemoved = folderRemoved
  },
  updateFolders: (state, folders) => {
    for (const folder of folders) {
      const index = state.folders.findIndex(stateFolder => stateFolder?.id === folder.id)
      if (index >= 0) {
        state.folders.splice(index, 1, { ...state.folders[index], ...folder })
      } else {
        state.folders.push(folder)
      }
    }
  },
  removeFolder: (state, folderToBeRemovedId) => {
    const index = state.folders.findIndex(folder =>
      folder.id === folderToBeRemovedId
    )
    state.folders.splice(index, 1)
  },
  setUpdatingFolder: (state, properties) => {
    for (const property of properties) {
      state.updatingFolder[property] = true
    }
  },
  resetUpdatingFolder: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingFolder[property] = false
      }
    } else {
      Object.keys(state.updatingFolder).forEach(property => state.updatingFolder[property] = false)
    }
  },
  setFolderUpdated: (state, properties) => {
    for (const property of properties) {
      state.folderUpdated[property] = true
      setTimeout(() => {
        state.folderUpdated[property] = false
      }, 5000)
    }
  },
  resetFolderUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.folderUpdated[property] = false
      }
    } else {
      Object.keys(state.folderUpdated).forEach(property => state.folderUpdated[property] = false)
    }
  }
}