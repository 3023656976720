export default {
  setAiPrefillFieldFeedback: (state, aiPrefillFieldFeedback) => {
    state.aiPrefillFieldFeedback = aiPrefillFieldFeedback
  },
  setSubmittingAiPrefillFieldFeedback: (state, submittingAiPrefillFieldFeedback) => {
    state.submittingAiPrefillFieldFeedback = submittingAiPrefillFieldFeedback
  },
  setAiPrefillFieldFeedbackSubmitted: (state, aiPrefillFieldFeedbackSubmitted) => {
    state.aiPrefillFieldFeedbackSubmitted = aiPrefillFieldFeedbackSubmitted
    if (aiPrefillFieldFeedbackSubmitted) {
      setTimeout(() => {
        state.aiPrefillFieldFeedbackSubmitted = false
      }, 5000)
    }
  },
  setUpdatingAiPrefillFieldFeedback: (state, updatingAiPrefillFieldFeedback) => {
    state.updatingAiPrefillFieldFeedback = updatingAiPrefillFieldFeedback
  },
  setAiPrefillFieldFeedbackUpdated: (state, aiPrefillFieldFeedbackUpdated) => {
    state.aiPrefillFieldFeedbackUpdated = aiPrefillFieldFeedbackUpdated
    if (aiPrefillFieldFeedbackUpdated) {
      setTimeout(() => {
        state.aiPrefillFieldFeedbackUpdated = false
      }, 5000)
    }
  }
}