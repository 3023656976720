export default {
  setLoadingAutomations: (state, loadingAutomations) => {
    state.loadingAutomations = loadingAutomations
  },

  setAddingAutomation: (state, addingAutomation) => {
    state.addingAutomation = addingAutomation
  },

  setAutomationAdded: (state, automationAdded) => {
    state.automationAdded = automationAdded
  },

  setAutomations: (state, automations) => {
    state.automations = automations
  },

  setAutomationAddError: (state, automationAddError) => {
    state.automationAddError = automationAddError
  },

  updateAutomations: (state, automations) => {
    for (const automation of automations) {
      const indexOfAutomation = state.automations.findIndex(stateAutomation =>
        stateAutomation.id === automation.id
      )
      const automationInStore = state.automations[indexOfAutomation]

      const automationsToBeUpdated = [{
        index: indexOfAutomation,
        automation
      }]

      if (automationInStore && automation.sortingOrder) {
        for (const [index, stateAutomation] of state.automations.entries()) {
          if (stateAutomation.id !== automation.id) {
            if (stateAutomation.sortingOrder < automationInStore.sortingOrder) {
              if (stateAutomation.sortingOrder >= automation.sortingOrder) {
                automationsToBeUpdated.push({
                  index,
                  automation: {
                    sortingOrder: stateAutomation.sortingOrder + 1
                  }
                })
              }
            } else if (stateAutomation.sortingOrder > automationInStore.sortingOrder) {
              if (stateAutomation.sortingOrder <= automation.sortingOrder) {
                automationsToBeUpdated.push({
                  index,
                  automation: {
                    sortingOrder: stateAutomation.sortingOrder - 1
                  }
                })
              }
            }
          }
        }
      }

      for (const automationToBeUpdated of automationsToBeUpdated) {
        if (automationToBeUpdated.index >= 0) {
          state.automations.splice(automationToBeUpdated.index, 1, {
            ...state.automations[automationToBeUpdated.index],
            ...automationToBeUpdated.automation
          })
        } else {
          state.automations.push(automationToBeUpdated.automation)
        }
      }
    }
  },

  setUpdatingAutomation: (state, properties) => {
    for (const property of properties) {
      state.updatingAutomation[property] = true
    }
  },

  setAutomationUpdated: (state, properties) => {
    for (const property of properties) {
      state.automationUpdated[property] = true
      setTimeout(() => {
        state.automationUpdated[property] = false
      }, 5000)
    }
  },

  setAutomationUpdateError: (state, automationUpdateError) => {
    state.automationUpdateError = automationUpdateError
  },

  setAutomationRemoved: (state, automationRemoved) => {
    state.automationRemoved = automationRemoved
  },

  setRemovingAutomation: (state, removingAutomation) => {
    state.removingAutomation = removingAutomation
  },

  resetUpdatingAutomation: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingAutomation[property] = false
      }
    } else {
      Object.keys(state.updatingAutomation).forEach(
        property => state.updatingAutomation[property] = false
      )
    }
  },

  resetAutomationUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.automationUpdated[property] = false
      }
    } else {
      Object.keys(state.automationUpdated).forEach(
        property => state.automationUpdated[property] = false
      )
    }
  },

  removeAutomation: (state, automationId) => {
    const index = state.automations.findIndex(automation => automation.id === automationId)
    state.automations.splice(index, 1)
  }
}
