import Vue from "vue"
import "@/plugins/constants"
import "@/plugins/registerServiceWorker"
import "@/plugins/filters"
import "@/plugins/validations"
import "@/plugins/moment"
import "@/plugins/charts"
import App from "@/App.vue"
import i18n from "@/plugins/i18n"
import router from "@/plugins/router"
import store from "@/plugins/vuex"
import vuetify from "@/plugins/vuetify"
import { ClickOutside } from "vuetify/lib/directives"

Vue.config.productionTip = false
Vue.directive("click-outside", ClickOutside)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app")