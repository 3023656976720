import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"
import store from "@/plugins/vuex"

const path = "/v1/folders"

export default {
  /**
   * This action will load folders.
   * @param {*} context it is the store
   * @param {*} payload it is the filter criteria
   */
  async loadFolders(context, payload) {
    context.commit("setLoadingFolders", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getFoldersResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getFoldersResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateFolders", getFoldersResponse.data)
      } else {
        context.commit("setFolders", getFoldersResponse.data)
      }
    }

    context.commit("setLoadingFolders", false)
  },

  /**
   * This action adds folders to an issue.
   * @param {*} context is the store.
   */
  async addFolder(context, payload) {
    context.commit("setAddingFolder", true)
    context.commit("setFolderAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postFolderResponse = await httpClient.post(undefined, payload)

    if (postFolderResponse.status === HTTP_STATUS_CODE.OK) {
      const folder = postFolderResponse.data
      store.dispatch("accessControl/loadLoggedInUserAccess", [{
        policies   : ["Folder update", "Folder remove"],
        resourceIds: [folder.id]
      }], { root: true })

      context.commit("updateFolders", [postFolderResponse.data])
      context.commit("setFolderAdded", true)
    }

    context.commit("setAddingFolder", false)
  },

  /**
   * This action will update folder.
   * @param {*} context is the store.
   * @param {*} folder will contain details of folder to be updated.
   */
  async updateFolder(context, folder) {
    const id = folder.id
    delete folder.id
    const propertiesToBeUpdated = Object.keys(folder)
    for (const property of propertiesToBeUpdated) {
      if (folder[property] === undefined) {
        folder[property] = null
      }
    }
    context.commit("setUpdatingFolder", propertiesToBeUpdated)
    context.commit("resetFolderUpdated", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateFolderResponse = await httpClient.put(`/${id}`, folder)

    if (updateFolderResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateFolders", [{
        id,
        ...folder
      }])
      context.commit("setFolderUpdated", propertiesToBeUpdated)
    }

    context.commit("resetUpdatingFolder", propertiesToBeUpdated)
  },

  /**
  * This action deletes folders from an issue.
  * @param {*} context is the store.
  */
  async removeFolder(context, folderId) {
    context.commit("setRemovingFolder", true)
    context.commit("setFolderRemoved", false)
    const httpClient         = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)
    const postFolderResponse = await httpClient.delete(`/${folderId}`)
    if (postFolderResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("removeFolder", folderId)
      context.commit("setFolderRemoved", true)
    }

    context.commit("setRemovingFolder", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setFolders", new Array())
    context.commit("setLoadingFolders", undefined)
    context.commit("setAddingFolder", false)
    context.commit("setFolderAdded", false)
    context.commit("resetUpdatingFolder")
    context.commit("resetFolderUpdated")
    context.commit("setRemovingFolder", false)
    context.commit("setFolderRemoved", false)
  }
}