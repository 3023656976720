import { defaultTableHeaderClasses } from "@/constants"
export const AUTOMATIONS =  {
  headers: [
    {
      value: "sortingOrder",
      class: defaultTableHeaderClasses,
      width: "50"
    },
    {
      text : "339",
      align: "left",
      value: "name",
      class: defaultTableHeaderClasses
    },
    {
      value   : "actions",
      align   : "left",
      class   : defaultTableHeaderClasses,
      sortable: false
    }
  ]
}