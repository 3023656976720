export default {
  loadingReports: undefined,
  reports       : new Array(),
  updatingReport: {
    status: false
  },
  reportUpdated: {
    status: false
  },
  loadingReportFormValues: undefined,
  reportFormValues       : new Array(),
  accessInformation      : new Object(),
  reportContentCleared   : false,
  clearingReportContent  : false
}