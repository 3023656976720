import { defaultTableHeaderClasses, defaultTableFooterOptions, defaultTableItemsPerPage } from "@/constants"

export const GROUP_USERS = {
  headers: [{
    text    : "177",
    align   : "left",
    value   : "name",
    class   : defaultTableHeaderClasses,
    sortable: true
  }, {
    text    : "178",
    align   : "left",
    value   : "email",
    class   : defaultTableHeaderClasses,
    sortable: true
  }, {
    text    : "127",
    align   : "left",
    value   : "status",
    class   : defaultTableHeaderClasses,
    sortable: true
  }, {
    text    : "179",
    align   : "left",
    value   : "action",
    class   : defaultTableHeaderClasses,
    sortable: true
  }],
  loading     : true,
  noDataText  : "180",
  itemsPerPage: defaultTableItemsPerPage,
  footer      : {
    ...defaultTableFooterOptions,
    itemsPerPageText: "181"
  }
}