import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/issue-fields"

export default {
  /**
   * This action will load issue fields.
   * @param {*} context it is the store.
   * @param {*} payload it is the filter criteria
   */
  async loadIssueFields(context, payload) {
    context.commit("setLoadingIssueFields", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getIssueFieldsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getIssueFieldsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateIssueFields", getIssueFieldsResponse.data)
      } else {
        context.commit("setIssueFields", getIssueFieldsResponse.data)
      }
    }

    context.commit("setLoadingIssueFields", false)
  },

  /**
   * This action will add new fields to issue customisation.
   * @param {*} context is the store.
   * @param {*} payload contains the details of issue type and fieldId to be added.
   */
  async addIssueFields(context, payload) {
    context.commit("setAddingIssueFields", true)
    context.commit("setIssueFieldsAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postIssueFieldsAddResponse = await httpClient.post(undefined, payload)

    if (postIssueFieldsAddResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setIssueFieldsAdded", true)
      context.commit("updateIssueFields", postIssueFieldsAddResponse.data)
    }
    context.commit("setAddingIssueFields", false)
  },

  /**
   * This action updates issue field.
   * @param {*} context is the store.
   * @param {*} issueField contains the details to be updated.
   */
  async updateIssueField(context, issueField) {
    const id = issueField.id
    delete issueField.id
    const propertiesToBeUpdated = Object.keys(issueField)
    for (const property of propertiesToBeUpdated) {
      if (issueField[property] === undefined) {
        issueField[property] = null
      }
    }
    context.commit("setUpdatingIssueField", propertiesToBeUpdated)
    context.commit("resetIssueFieldUpdated", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateFieldResponse = await httpClient.put(`/${id}`, issueField)

    if (updateFieldResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateIssueFields", [{
        id,
        ...issueField
      }])
      context.commit("setIssueFieldUpdated", propertiesToBeUpdated)
    }

    context.commit("resetUpdatingIssueField", propertiesToBeUpdated)
  },

  /**
   * This action removes issue fields.
   * @param {*} context is the store.
   * @param {*} payload contains the id of the issue field
   */
  async removeIssueField(context, payload) {
    context.commit("setRemovingIssueField", true)
    context.commit("setIssueFieldRemoved", false)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteIssueFieldResponse = await httpClient.delete(`/${payload}`)

    if (deleteIssueFieldResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("issueFieldRemove", payload)
      context.commit("setIssueFieldRemoved", true)
    }

    context.commit("setRemovingIssueField", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setIssueFields", new Array())
    context.commit("setLoadingIssueFields", undefined)
    context.commit("resetUpdatingIssueField")
    context.commit("resetIssueFieldUpdated")
    context.commit("setRemovingIssueField", false)
    context.commit("setIssueFieldRemoved", false)
  }
}