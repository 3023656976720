export default {
  isLoadingChannels                    : state => state.loadingChannels,
  channels                             : state => state.channels,
  isChannelAdded                       : state => state.channelAdded,
  isAddingChannel                      : state => state.addingChannel,
  isUpdatingName                       : state => state.updatingChannel.name,
  isNameUpdated                        : state => state.channelUpdated.name,
  isUpdatingDisplayName                : state => state.updatingChannel.displayName,
  isDisplayNameUpdated                 : state => state.channelUpdated.displayName,
  isUpdatingLink                       : state => state.updatingChannel.link,
  isLinkUpdated                        : state => state.channelUpdated.link,
  isUpdatingLinkDisplayName            : state => state.updatingChannel.linkDisplayName,
  isLinkDisplayNameUpdated             : state => state.channelUpdated.linkDisplayName,
  isUpdatingDescription                : state => state.updatingChannel.description,
  isDescriptionUpdated                 : state => state.channelUpdated.description,
  isUpdatingTranslationPreference      : state => state.updatingChannel.translationPreferenceId,
  isTranslationPreferenceUpdated       : state => state.channelUpdated.translationPreferenceId,
  isUpdatingOrganisationCode           : state => state.updatingChannel.organisationCode,
  isOrganisationCodeUpdated            : state => state.channelUpdated.organisationCode,
  channelAddError                      : state => state.channelAddError,
  nameUpdateError                      : state => state.channelUpdateError.name,
  displayNameUpdateError               : state => state.channelUpdateError.displayName,
  formsOfChannels                      : state => state.formsOfChannels,
  isAddingChannelForm                  : state => state.addingChannelForm,
  isRemovingChannelForm                : state => state.removingChannelForm,
  isLoadingForms                       : state => state.loadingForms,
  isUpdatingEnabled                    : state => state.updatingChannel.enabled,
  isEnabledUpdated                     : state => state.channelUpdated.enabled,
  translationConfigurations            : state => state.translationConfigurations,
  isLoadingTranslationConfigurations   : state => state.loadingTranslationConfigurations,
  isUpdatingTranslationConfigurations  : state => state.updatingTranslationConfigurations,
  isTranslationConfigurationsUpdated   : state => state.translationConfigurationsUpdated,
  isChannelLogoAdded                   : state => state.channelLogoAdded,
  isAddingChannelLogo                  : state => state.addingChannelLogo,
  isChannelLogoRemoved                 : state => state.channelLogoRemoved,
  isRemovingChannelLogo                : state => state.removingChannelLogo,
  isDownloadingChannelsOverview        : state => state.downloadingChannelsOverview,
  formTemplatesOfChannels              : state => state.formTemplatesOfChannels,
  isAddingChannelFormTemplates         : state => state.addingChannelFormTemplates,
  channelFormTemplatesAdded            : state => state.channelFormTemplatesAdded,
  isRemovingChannelFormTemplate        : state => state.removingChannelFormTemplate,
  isChannelFormTemplateRemoved         : state => state.channelFormTemplateRemoved,
  isConsentUpdated                     : state => state.channelUpdated.consent,
  isUpdatingConsent                    : state => state.updatingChannel.consent,
  isDownloadingDescriptionTranslations : state => state.downloadingDescriptionTranslations,
  isDownloadingTriageTranslations      : state => state.downloadingTriageTranslations,
  isUploadingDescriptionTranslationFile: state => state.uploadingDescriptionTranslationFile,
  isDescriptionTranslationFileUploaded : state => state.descriptionTranslationFileUploaded,
  isUploadingTriageTranslationFile     : state => state.uploadingTriageTranslationFile,
  isTriageTranslationFileUploaded      : state => state.triageTranslationFileUploaded,
  isUpdatingTriage                     : state => state.updatingChannel.speakupDescription
    || state.updatingChannel.triageUrl
    || state.updatingChannel.alternativeDescription,
  isTriageUpdated: state => state.channelUpdated.speakupDescription
    || state.channelUpdated.triageUrl
    || state.channelUpdated.alternativeDescription,
  isUpdatingDraftPrivacyPolicyText            : state => state.updatingChannel.draftPrivacyPolicyText,
  isDraftPrivacyPolicyTextUpdated             : state => state.channelUpdated.draftPrivacyPolicyText,
  isUpdatingPrivacyPolicy                     : state => state.updatingChannel.publishPrivacyPolicy,
  isPrivacyPolicyUpdated                      : state => state.channelUpdated.publishPrivacyPolicy,
  draftPrivacyPolicyTranslations              : state => state.draftPrivacyPolicyTranslations,
  privacyPolicyTranslations                   : state => state.privacyPolicyTranslations,
  isLoadingPrivacyPolicyTranslations          : state => state.loadingPrivacyPolicyTranslations,
  isUploadingDraftPrivacyPolicyTranslations   : state => state.uploadingDraftPrivacyPolicyTranslations,
  isDraftPrivacyPolicyTranslationsFileUploaded: state => state.draftPrivacyPolicyTranslationsFileUploaded,
  isDisablingPrivacyPolicy                    : state => state.updatingChannel.privacyPolicy,
  isPrivacyPolicyDisabled                     : state => state.channelUpdated.privacyPolicy
}