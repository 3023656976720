export default {
  isLoadingReports         : state => state.loadingReports,
  reports                  : state => state.reports,
  isUpdatingStatus         : state => state.updatingReport.status,
  isStatusUpdated          : state => state.reportUpdated.status,
  isloadingReportFormValues: state => state.loadingReportFormValues,
  reportFormValues         : state => state.reportFormValues,
  accessInformation        : state => state.accessInformation,
  isReportContentCleared   : state => state.reportContentCleared,
  isClearingReportContent  : state => state.clearingReportContent
}