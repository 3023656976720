import store from "@/plugins/vuex"
import { ISSUE_ROLES } from "@/constants"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { DOMAIN } from "@/constants/bread-crumbs/domain"
import { PAGES } from "@/constants"

/**
 * This method will be called before navigating to domain page.
 * @param {*} to route to be navigated to.
 */
export const beforeDomain = async (to, from) => {
  if (from.name !== "domain" || !(PAGES.DOMAIN.includes(to.hash) || PAGES.DOMAIN.includes(from.hash))) {
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.DOMAIN))
    await store.dispatch("domains/loadDomain", +to.params.id)
    const domains = store.getters["domains/domains"]
    const domain  = domains.find(domain => domain.id === +to.params.id)
    if (!domain) {
      return "dashboard"
    } else {
      store.commit("shared/setBreadcrumbs", DOMAIN(to, domain))
      store.dispatch("channels/loadChannels")
      await store.dispatch("groups/loadGroups")
      store.dispatch("users/loadUsers")
      const groups = store.getters["groups/groups"]
      for (const group of groups) {
        store.dispatch("groups/loadUsers", group.id)
      }
      store.dispatch("roles/loadRoles")
      store.dispatch("accesses/loadAccesses", { roleId: ISSUE_ROLES })
      store.commit("domains/resetUpdatingDomain")
      store.commit("domains/resetDomainUpdated")
      store.commit("domains/resetDomainUpdateError")
      store.commit("accesses/setAddingAccess", undefined)
      store.commit("accesses/setAccessAdded", undefined)
      store.commit("accesses/setRemovingAccess", undefined)
      store.commit("accesses/setAccessRemoved", undefined)
      store.dispatch("domains/loadDomains")
      store.dispatch("fields/loadFieldsV2")
      store.dispatch("formTemplates/loadFormTemplates")
    }
  }
}