/**
 * @file It contains vuex token module getter methods to access store variables.
 */
export default {
  clientName                 : state => state.clientName,
  clientConfigurationId      : state => state.clientConfigurationId,
  clientUserPoolId           : state => state.clientUserPoolId,
  clientUserPoolClientId     : state => state.clientUserPoolClientId,
  isLoggedIn                 : state => state.loggedIn,
  isLoggingIn                : state => state.loggingIn,
  loggedInUser               : state => state.loggedInUser,
  isLoggedInUserDisabled     : state => state.loggedInUserDisabled,
  loggedInUserRoleTypes      : state => state.loggedInUserRoleTypes,
  isPasswordResetRequested   : state => state.passwordResetRequested,
  isRequestingPasswordReset  : state => state.requestingPasswordReset,
  isPasswordReset            : state => state.passwordReset,
  isInvalidCredentials       : state => state.invalidCredentials,
  isResettingPassword        : state => state.resettingPassword,
  isNewPasswordRequired      : state => state.newPasswordRequired,
  secretCode                 : state => state.secretCode,
  isTokenVerified            : state => state.tokenVerified,
  isVerifyingToken           : state => state.verifyingToken,
  isMfaEnabledForLoggedInUser: state => state.mfaEnabledForLoggedInUser,
  isAssignedUserMfaPreference: state => state.assignedUserMfaPreference,
  isMfaEnabled               : state => state.mfaEnabled,
  isTotpRequired             : state => state.totpRequired,
  hasExceededAttempts        : state => state.exceededAttempts,
  isChangingPassword         : state => state.changingPassword,
  isPasswordChanged          : state => state.passwordChanged,
  isPasswordExpired          : state => state.passwordExpired,
  isInvalidTotp              : state => state.invalidTotp,
  verificationCode           : state => state.verificationCode,
  region                     : state => state.region
}