export const PAGE_TITLES = {
  ACCESSES             : "43",
  ANALYTICS            : "710",
  CHANNEL_ADD          : "285",
  CHANNEL              : "285",
  CHANNELS             : "77",
  DASHBOARD            : "23",
  DOMAIN               : "189",
  DOMAINS              : "59",
  EXCEPTIONS           : "97",
  EXPORT               : "698",
  GROUP                : "173",
  GROUPS               : "136",
  ISSUE_ACCESSES       : "287",
  CASE                 : "289",
  TASK                 : "1593",
  ISSUES               : "246",
  LOGS                 : "348",
  ROLES                : "89",
  SUPPORT              : "349",
  USER_ADD             : "168",
  USER                 : "150",
  USERS                : "122",
  REPORTER_INTAKE_FORMS: "736",
  OPTION_LISTS         : "730",
  FIELDS               : "742",
  CONFIGURATIONS       : "1021",
  ISSUE_FORMS          : "1204",
  ISSUE_CUSTOMISATION  : "890",
  USER_SETTINGS        : "1177",
  AUTOMATIONS          : "1089",
  AUTOMATION_ADD       : "920",
  ISSUE_FIELDS         : "934",
  WORKFLOWS            : "1419",
  SCREENS              : "1389",
  REPLY_TEMPLATES      : "1714",
  ROLE                 : "1899"
}

export const PAGE_TITLE_WITH_TRANSLATION = value => ({
  value,
  useTranslation: true
})

export const PAGE_TITLE_WITHOUT_TRANSLATION = value => ({
  value,
  useTranslation: false
})