/**
 * @file It contains vuex kpis module mutations to mutate the state variables.
 */
export default {
  setLoadingKpis: (state, loadingKpis) => {
    state.loadingKpis = loadingKpis
  },
  setKpis: (state, kpis) => {
    state.kpis = kpis
  }
}