export default {
  setLoadingMessageItems: (state, loadingMessageItems) => {
    state.loadingMessageItems = loadingMessageItems
  },
  setDownloadingFile: (state, downloadingFile) => {
    state.downloadingFile = downloadingFile
  },
  setMessageItems: (state, messageItems) => {
    state.messageItems = messageItems
  },
  setAddingMessageItem: (state, addingMessageItem) => {
    state.addingMessageItem = addingMessageItem
  },
  setMessageItemAdded: (state, messageItemAdded) => {
    state.messageItemAdded = messageItemAdded
  },
  setHumanTranslatingMessageItem: (state, humanTranslatingMessageItem) => {
    state.humanTranslatingMessageItem = humanTranslatingMessageItem
  },
  setMessageItemHumanTranslated: (state, messageItemHumanTranslated) => {
    state.messageItemHumanTranslated = messageItemHumanTranslated
  },
  updateMessageItems: (state, messageItems) => {
    for (const messageItem of messageItems) {
      const index = state.messageItems.findIndex(stateMessageItem => stateMessageItem?.id === messageItem.id)
      if (index >= 0) {
        state.messageItems.splice(index, 1, { ...state.messageItems[index], ...messageItem })
      } else {
        state.messageItems.push(messageItem)
      }
    }
  },
  setUpdatingMessageItem: (state, properties) => {
    for (const property of properties) {
      state.updatingMessageItem[property] = true
    }
  },
  resetUpdatingMessageItem: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingMessageItem[property] = false
      }
    } else {
      Object.keys(state.updatingMessageItem).forEach(property => state.updatingMessageItem[property] = false)
    }
  },
  setMessageItemUpdated: (state, properties) => {
    for (const property of properties) {
      state.messageItemUpdated[property] = true
      setTimeout(() => {
        state.messageItemUpdated[property] = false
      }, 5000)
    }
  },
  resetMessageItemUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.messageItemUpdated[property] = false
      }
    } else {
      Object.keys(state.messageItemUpdated).forEach(property => state.messageItemUpdated[property] = false)
    }
  }
}