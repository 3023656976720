import store from "@/plugins/vuex"
import { CLIENT_FEATURES } from "@/constants"

/**
 * This method returns true if the user has access to the module/route .
 * @param {*} roleTypes list of role types to which the route or module has access.
 */
export const hasAccessToRoute = typesOfRoleWhichHaveAccess => {
  const loggedInUserRoleTypes = store.getters["auth/loggedInUserRoleTypes"]
  return !!typesOfRoleWhichHaveAccess.find(typeOfRoleWhichHasAccess =>
    !!loggedInUserRoleTypes.find(loggedInUserRoleType =>
      typeOfRoleWhichHasAccess === loggedInUserRoleType
    )
  )
}

export const hasToShowSubModule = moduleAction => {
  if (CLIENT_FEATURES[moduleAction]) {
    const feature = CLIENT_FEATURES[moduleAction]

    if (feature.dependencies) {
      const dependentSubModules             = feature.dependencies
      const dependentSubModuleEnabledStatus = dependentSubModules.some(subModule => hasToShowSubModule(subModule))
      return dependentSubModuleEnabledStatus
    }

    let featureEnabledStatus

    if (Array.isArray(feature.getterName)) {
      featureEnabledStatus = feature.getterName.some(getterName => {
        const completeGetterName = `configurations/${getterName}`
        return store.getters[completeGetterName] === true
      })
    } else {
      const completeGetterName = `configurations/${feature.getterName}`
      featureEnabledStatus     = store.getters[completeGetterName]
    }

    return featureEnabledStatus
  }
  return true
}

export const hasClientAccessToRoute = routeName => {
  const features = Object.keys(CLIENT_FEATURES)
  for (const feature of features) {
    if (CLIENT_FEATURES[feature].routes.includes(routeName)) {
      return hasToShowSubModule(feature)
    }
  }
  return true
}