import { REPORTER_FORM_TEMPLATES } from "./tables/reporter-form-templates"
import { OPTION_LISTS } from "./tables/option-lists"
import { FIELDS } from "./tables/fields"
import { FORM_TEMPLATE_FIELDS } from "./tables/form-template-fields"
import { FORM_TEMPLATE_ADD_FIELDS } from "./tables/form-template-add-fields"
import { CHANNEL_FORM_TEMPLATES } from "./tables/channel-form-templates"
import { USERS } from "./tables/user"
import { GROUP_USERS } from "./tables/group-users"
import { DOMAINS } from "./tables/domains"
import { CHANNELS } from "./tables/channels"
import { GROUPS } from "./tables/groups"
import { ISSUES } from "./tables/issues"
import { ROLES } from "./tables/roles"
import { EXCEPTIONS } from "./tables/exceptions"
import { CHANNEL_TRANSLATION_CONFIGURATIONS } from "./tables/channel-translation-configurations"
import { LOGS } from "./tables/logs"
import { ISSUE_DOCUMENTS } from "./tables/issue-documents"
import { USERS_WITH_ISSUE_ACCESS } from "./tables/users-with-issue-access"
import { GROUPS_WITH_ISSUE_ACCESS } from "./tables/groups-with-issue-access"
import { GROUPS_WITH_ACCESS } from "./tables/groups-with-access"
import { USERS_WITH_ACCESS } from "./tables/users-with-access"
import { DOMAIN_CHANNELS } from "./tables/domain-channels"
import { ISSUE_FORMS } from "./tables/issue-forms"
import { ISSUE_FORM_FIELDS } from "./tables/issue-form-fields"
import { ISSUE_FORM_TEMPLATE_ADD_FIELDS } from "./tables/issue-form-template-add-fields"
import { ISSUE_FORM_TEMPLATES } from "./tables/issue-form-templates"
import { ISSUE_ADD_CUSTOM_FIELDS } from "./tables/issue-add-custom-fields"
import { ISSUE_FIELDS } from "./tables/issue-fields"
import { DATA_RETENTION_RULES } from "./tables/data-retention-rules"
import { NOTIFICATIONS } from "./tables/notifications"
import { AUTOMATIONS } from "./tables/automations"
import { WORKFLOWS } from "./tables/workflows"
import { TRANSITION_POST_FUNCTIONS } from "./tables/transition-post-functions"
import { TRANSITION_PRE_CONDITIONS } from "./tables/transition-pre-conditions"
import { SCREENS } from "./tables/screens"
import { SCREEN_ADD_ITEMS } from "./tables/screen-add-items"
import { REPLY_TEMPLATES } from "./tables/reply-templates"
import { AI_PREFILL_FIELDS } from "./tables/ai-prefill-fields"
import { CSV_DATA_VALIDATION } from "./tables/csv-data-validation"
import { ROLE_ADD_PERMISSIONS } from "./tables/role-add-permissions"
import { ROLE_PERMISSIONS } from "./tables/role-permissions"

export default {
  LOGS,
  USERS,
  ROLES,
  GROUPS,
  ISSUES,
  FIELDS,
  DOMAINS,
  CHANNELS,
  EXCEPTIONS,
  GROUP_USERS,
  OPTION_LISTS,
  DOMAIN_CHANNELS,
  ISSUE_DOCUMENTS,
  FORM_TEMPLATE_FIELDS,
  CHANNEL_FORM_TEMPLATES,
  USERS_WITH_ISSUE_ACCESS,
  REPORTER_FORM_TEMPLATES,
  FORM_TEMPLATE_ADD_FIELDS,
  GROUPS_WITH_ISSUE_ACCESS,
  CHANNEL_TRANSLATION_CONFIGURATIONS,
  GROUPS_WITH_ACCESS,
  USERS_WITH_ACCESS,
  ISSUE_FORMS,
  ISSUE_FORM_FIELDS,
  ISSUE_FORM_TEMPLATE_ADD_FIELDS,
  ISSUE_FORM_TEMPLATES,
  ISSUE_ADD_CUSTOM_FIELDS,
  ISSUE_FIELDS,
  DATA_RETENTION_RULES,
  NOTIFICATIONS,
  AUTOMATIONS,
  WORKFLOWS,
  AI_PREFILL_FIELDS,
  TRANSITION_PRE_CONDITIONS,
  TRANSITION_POST_FUNCTIONS,
  SCREENS,
  SCREEN_ADD_ITEMS,
  REPLY_TEMPLATES,
  ROLE_PERMISSIONS,
  CSV_DATA_VALIDATION,
  ROLE_ADD_PERMISSIONS
}