export default {
  workflows          : [],
  loadingWorkflows   : false,
  updatingWorkflow   : false,
  workflowUpdated    : false,
  workflowUpdateError: false,
  addingWorkflow     : false,
  workflowAdded      : false,
  workflowAddError   : null,
  statusUpdated      : false,
  statusErrors       : []
}