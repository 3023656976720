/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/languages"

export default {
  /**
   * This action will load languages.
   * @param {*} context is the store.
   */
  async loadLanguages(context) {
    context.commit("setLoadingLanguages", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getLanguagesResponse = await httpClient.get()

    if (getLanguagesResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setLanguages", getLanguagesResponse.data)
    } else {
      // something went wrong while getting languages
    }

    context.commit("setLoadingLanguages", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingLanguages", undefined)
    context.commit("setLanguages", new Array())
  }
}