import { defaultTableHeaderClasses } from "@/constants"
export const AI_PREFILL_FIELDS =  {
  headers: [
    {
      text : "1788",
      align: "left",
      value: "label",
      class: defaultTableHeaderClasses
    }, {
      text : "1789",
      align: "left",
      value: "aiSuggestedValue",
      class: defaultTableHeaderClasses
    }, {
      align: "left",
      value: "data-table-select",
      class: defaultTableHeaderClasses
    }
  ]
}