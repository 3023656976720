import { defaultTableHeaderClasses, defaultTableFooterOptions, defaultTableItemsPerPage } from "@/constants"

export const GROUPS =  {
  headers: [{
    text    : "140",
    align   : "left",
    value   : "name",
    class   : defaultTableHeaderClasses,
    sortable: true
  }, {
    text    : "141",
    align   : "left",
    value   : "numberOfUsers",
    class   : defaultTableHeaderClasses,
    sortable: true
  }],
  loading     : true,
  loadingText : "142",
  noDataText  : "143",
  noResultText: "144",
  itemsPerPage: defaultTableItemsPerPage,
  footer      : {
    ...defaultTableFooterOptions,
    itemsPerPageText: "145"
  }
}