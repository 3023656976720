export default {
  setWorkflowAssociations: (state, workflowAssociations) => {
    state.workflowAssociations = workflowAssociations
  },
  setLoadingWorkflowAssociations: (state, loadingWorkflowAssociations) => {
    state.loadingWorkflowAssociations = loadingWorkflowAssociations
  },
  updateWorkflowAssociations: (state, workflowAssociations) => {
    for (const workflowAssociation of workflowAssociations) {
      const index = state.workflowAssociations.findIndex(
        stateWorkflowAssociation => stateWorkflowAssociation?.issueTypeId === workflowAssociation.issueTypeId)
      if (index >= 0) {
        state.workflowAssociations.splice(index, 1, { ...state.workflowAssociations[index], ...workflowAssociation })
      } else {
        state.workflowAssociations.push(workflowAssociation)
      }
    }
  },
  setAddingWorkflowAssociation: (state, addingWorkflowAssociation) => {
    state.addingWorkflowAssociation = addingWorkflowAssociation
  },
  setWorkflowAssociationAdded: (state, workflowAssociationAdded) => {
    state.workflowAssociationAdded = workflowAssociationAdded
  },
  setWorkflowAssociationAddError: (state, workflowAssociationAddError) => {
    state.workflowAssociationAddError = workflowAssociationAddError
  }
}