import { defaultTableHeaderClasses, defaultTableFooterOptions, defaultTableItemsPerPage } from "@/constants"
import { compareDates }  from "@/utils"

export const LOGS = {
  headers: [{
    text : "531",
    align: "left",
    value: "createdAt",
    sort : compareDates,
    class: defaultTableHeaderClasses
  },
  {
    text : "532",
    align: "left",
    value: "level",
    class: defaultTableHeaderClasses
  },
  {
    text : "533",
    align: "left",
    value: "entity",
    class: defaultTableHeaderClasses
  },
  {
    text : "534",
    align: "left",
    value: "entityId",
    class: defaultTableHeaderClasses
  },
  {
    text : "535",
    align: "left",
    value: "username",
    class: defaultTableHeaderClasses
  },
  {
    text : "536",
    align: "left",
    value: "message",
    class: defaultTableHeaderClasses
  }],
  itemsPerPage: defaultTableItemsPerPage,
  footer      : defaultTableFooterOptions
}