/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/support"

export default {
  /**
   * This action will request a support.
   * @param {*} context is the store.
   */
  async requestSupport(context, payload) {
    context.commit("setRequestingSupport", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postSupportResponse = await httpClient.post(undefined, payload)

    if (postSupportResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setSupportRequested", true)
    } else {
      // something went wrong while requesting a support
    }

    context.commit("setRequestingSupport", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setRequestingSupport", undefined)
    context.commit("setSupportRequested", undefined)
  }
}
