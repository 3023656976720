export default {
  isLoadingIssueDocuments      : state => state.loadingIssueDocuments,
  issueDocuments               : state => state.issueDocuments,
  isAddingIssueDocument        : state => state.addingIssueDocument,
  isRemovingIssueDocument      : state => state.removingIssueDocument,
  isIssueDocumentRemoved       : state => state.issueDocumentRemoved,
  accessInformation            : state => state.accessInformation,
  isUpdatingFolderId           : state => state.updatingIssueDocument.folderId,
  isFolderIdUpdated            : state => state.issueDocumentUpdated.folderId,
  isIssueDocumentAdded         : state => state.issueDocumentAdded,
  isUpdatingDocumentDescription: state => state.updatingIssueDocument.description,
  isDocumentDescriptionUpdated : state => state.issueDocumentUpdated.description
}