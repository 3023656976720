import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/option-list-items"

export default {

  /**
   * This action will load optionListItems.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load optionListItems.
   */
  async loadOptionListItems(context, payload) {
    context.commit("setLoadingOptionListItems", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getOptionListItemsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getOptionListItemsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateOptionListItems", getOptionListItemsResponse.data)
      } else {
        context.commit("setOptionListItems", getOptionListItemsResponse.data)
      }
    }

    context.commit("setLoadingOptionListItems", false)
  },

  /**
   * This action will delete a optionListItem.
   * @param {*} context is the store
   * @param {*} payload contains optionListItem id
   */
  async removeOptionListItem(context, payload) {
    context.commit("setOptionListItemRemoved", false)
    context.commit("setRemovingOptionListItem", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteOptionListItemResponse = await httpClient.delete(`/${payload}`)

    if (deleteOptionListItemResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      await context.dispatch("loadOptionListItems")
      context.commit("setOptionListItemRemoved", true)
    }
    context.commit("setRemovingOptionListItem", false)
  },

  /**
   * This action updates option list item.
   * @param {*} context is the store.
   * @param {*} payload contains the details to be updated.
   */
  async updateOptionListItem(context, payload) {
    const optionListId     = payload.optionListId
    const optionListItemId = payload.optionListItemId

    delete payload.optionListItemId
    delete payload.optionListId

    const propertiesToBeUpdated = Object.keys(payload)
    for (const property of propertiesToBeUpdated) {
      if (payload[property] === undefined) {
        payload[property] = null
      }
    }
    context.commit("setUpdatingOptionListItem", propertiesToBeUpdated)
    context.commit("resetOptionListItemUpdated", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateFieldResponse = await httpClient.put(`/${optionListItemId}`, payload)

    if (updateFieldResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      await context.dispatch("loadOptionListItems", {
        optionListId
      })

      context.commit("setOptionListItemUpdated", propertiesToBeUpdated)
    }

    context.commit("resetUpdatingOptionListItem", propertiesToBeUpdated)
  },

  /**
   * This action will add a new optionListItem.
   * @param {*} context is the store
   * @param {*} payload contains optionListItem details
   */
  async addOptionListItem(context, payload) {
    context.commit("setAddingOptionListItem", true)
    context.commit("setOptionListItemAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postOptionListItemResponse = await httpClient.post(undefined, payload)

    if (postOptionListItemResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateOptionListItems", [postOptionListItemResponse.data])
      context.commit("setOptionListItemAdded", true)
    } else if (postOptionListItemResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setOptionListItemAddError", postOptionListItemResponse.data)
    }

    context.commit("setAddingOptionListItem", false)
  },

  /**
   * This action will add a new optionListItems.
   * @param {*} context is the store
   * @param {*} payload contains optionListItem details
   */
  async bulkAddOptionListItems(context, payload) {
    context.commit("setAddingBulkOptionListItems", true)
    context.commit("setBulkOptionListItemsAdded", false)
    context.commit("setNumberOfOptionListItemsAdded", undefined)
    context.commit("setNumberOfDuplicateOptionListItems", undefined)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    let numberOfOptionListItemsAdded     = 0
    let numberOfDuplicateOptionListItems = 0
    for (const optionListItem of payload.optionListItems) {

      const postOptionListItemResponse = await httpClient.post(undefined, {
        name        : optionListItem,
        optionListId: payload.optionListId
      })

      if (postOptionListItemResponse.status === HTTP_STATUS_CODE.OK) {
        context.commit("updateOptionListItems", [postOptionListItemResponse.data])
        numberOfOptionListItemsAdded++

      } else if (postOptionListItemResponse.status === HTTP_STATUS_CODE.CONFLICT) {
        numberOfDuplicateOptionListItems++
      }
    }
    context.commit("setNumberOfOptionListItemsAdded", numberOfOptionListItemsAdded)
    context.commit("setNumberOfDuplicateOptionListItems", numberOfDuplicateOptionListItems)
    context.commit("setBulkOptionListItemsAdded", true)
    context.commit("setAddingBulkOptionListItems", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingOptionListItems", undefined)
    context.commit("setAddingOptionListItem", undefined)
    context.commit("setOptionListItemAdded", undefined)
    context.commit("setOptionListItemAddError", undefined)
    context.commit("resetOptionListItems")
    context.commit("setOptionListItemRemoved", undefined)
    context.commit("setAddingBulkOptionListItems", undefined)
    context.commit("setBulkOptionListItemsAdded", undefined)
    context.commit("setNumberOfOptionListItemsAdded", undefined)
    context.commit("setNumberOfDuplicateOptionListItems", undefined)
    context.commit("setRemovingOptionListItem", undefined)
  }
}