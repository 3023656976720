import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to issue fields page.
 */
export const beforeIssueFields = async (to, from) => {
  if (!["issue-fields", "issue-field"].includes(from.name)) {
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ISSUE_FIELDS))
    store.commit("shared/setBreadcrumbs", undefined)

    store.dispatch("issueFields/loadIssueFields")
    store.dispatch("fields/loadFieldsV2")
    store.dispatch("optionLists/loadOptionLists")
  }
}
