import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to dashboard
 */
export const beforeDashboard = async () => {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.DASHBOARD))
  store.commit("shared/setBreadcrumbs", undefined)
  const loggedInUserRoleTypes = store.getters["auth/loggedInUserRoleTypes"]
  if (loggedInUserRoleTypes.includes("issue")) {
    store.dispatch("kpis/loadKPIs")
  }
}