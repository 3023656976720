import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/screens"

export default {
  /**
   * This action will load screens.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load screens.
   */
  async loadScreens(context, payload) {
    context.commit("setLoadingScreens", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getScreensResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getScreensResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateScreens", [getScreensResponse.data])
      } else {
        context.commit("setScreens", getScreensResponse.data)
      }
    }
    context.commit("setLoadingScreens", false)
  },

  /**
   * This action will add a screen.
   * @param {*} context is the store.
   * @param {*} payload contains name of the screen.
   */
  async addScreen(context, payload) {
    context.commit("setAddingScreen", true)
    context.commit("setScreenAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postScreenAddResponse = await httpClient.post(undefined, payload)
    if (postScreenAddResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateScreens", [postScreenAddResponse.data])
      context.commit("setScreenAdded", true)
    } else if (postScreenAddResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setScreenAddError", postScreenAddResponse.data)
    }

    context.commit("setAddingScreen", false)
  },

  /**
   * This action will load screen.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load screen.
   */
  async loadScreen(context, payload) {
    context.commit("setLoadingScreens", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getScreenResponse = await httpClient.get(`/${payload}`)

    if (getScreenResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateScreens", [getScreenResponse.data])
    }
    context.commit("setLoadingScreens", false)
  },

  /**
   * This action will load screen items.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load screen items.
   */
  async loadScreenItems(context, payload) {
    context.commit("setLoadingScreenItems", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getScreenItemsResponse = await httpClient.get(`/${payload.screenId}/items`)

    if (getScreenItemsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setScreenItems", getScreenItemsResponse.data.screenItems)
    }
    context.commit("setLoadingScreenItems", false)
  },

  /**
   * This action updates screen name
   * @param {*} context is the store.
   * @param {*} screen is the screen to be updated.
   */
  async updateScreen(context, screen) {
    const id = screen.id
    delete screen.id

    const propertiesToBeUpdated = Object.keys(screen)
    for (const property of propertiesToBeUpdated) {
      if (screen[property] === undefined) {
        screen[property] = null
      }
    }
    context.commit("setUpdatingScreen", propertiesToBeUpdated)
    context.commit("resetScreenUpdated", propertiesToBeUpdated)
    context.commit("resetScreenUpdateError", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateScreenResponse = await httpClient.put(`/${id}`, screen)

    if (updateScreenResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateScreens", [{
        id,
        ...screen
      }])
      context.commit("setScreenUpdated", propertiesToBeUpdated)
    } else if (updateScreenResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setScreenUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateScreenResponse.data
      })
    }
    context.commit("resetUpdatingScreen", propertiesToBeUpdated)
  },

  /**
   * This action will add screen items to a screen.
   * @param {*} context is the store.
   * @param {*} payload is data to add screen items.
   */
  async addScreenItems(context, payload) {
    const { screenId, screenItems } = payload
    context.commit("setAddingScreenItems", true)
    context.commit("setScreenItemsAdded", false)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postScreenItemsAddResponse = await httpClient.post(`/${screenId}/items`, screenItems)

    if (postScreenItemsAddResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateScreenItems", postScreenItemsAddResponse.data)
      context.commit("setScreenItemsAdded", true)
    }
    context.commit("setAddingScreenItems", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setScreens", new Array())
    context.commit("setLoadingScreens", false)
    context.commit("setScreenItems", new Array())
    context.commit("setLoadingScreenItems", false)
    context.commit("setAddingScreenItems", false)
    context.commit("setScreenItemsAdded", false)
    context.commit("resetUpdatingScreen")
    context.commit("resetScreenUpdated")
    context.commit("resetScreenUpdateError")
  }
}
